import { Action, Reducer } from 'redux';
import { AppThunkAction } from './../../store';
import { handleResponse } from './../../store/Library';
import { actionTypes } from './../../store/ActionTypes';
import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import {
    ReceiveOrganizerEmailMessageAction,
    OrganizerEmailMessageLoaderAction,
    DeleteOrganizerEmailMessageAction,
    SaveOrganizerEmailMessageAction,
    UpdateOrganizerEmailMessageAction,
    OrganizerEmailMessageStoreState,
    unloadedOrganizerEmailMessageStore,
    apiPrefix
} from './../models/OrganizerStoreModels';
import { EmailMessage, OrganizerCompanySettings, EmailMessageType, GlobalOperationAutoReminderRequest } from './../models/OrganizerComponentModels'
import { ActionCreators } from './OrganizerCompanySettingsStore';
import { StoreConstants } from './../components/Helper/OrganizerConstants';
import { LoggerFactory } from '../../Logger/LoggerFactory';
const logger = new LoggerFactory().getTelemetryLogger();

type KnownActions =
    ReceiveOrganizerEmailMessageAction |
    OrganizerEmailMessageLoaderAction |
    DeleteOrganizerEmailMessageAction |
    SaveOrganizerEmailMessageAction |
    UpdateOrganizerEmailMessageAction |
    NotificationAction

export const actionCreators = {
    requestEmailMessages: (): AppThunkAction<KnownActions> => (dispatch, getState) => {
        fetch(`${apiPrefix}EmailMessage`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<EmailMessage[]>)
            .then((data) => {
                dispatch({
                    type: actionTypes.RECEIVE_ORGANIZER_EMAIL_MESSAGES, data: data,
                    loading: false
                });
            }).catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.FetchEmailMessage, statusType: StatusType.Error, statusCode: error?.status
                });
                logger?.trackError(`requestEmailMessages failed for the request  with error ${error.message}`)
            }); 

        dispatch({ type: actionTypes.ORGANIZER_EMAIL_MESSAGE_LOADER, loading: true });
    },

    saveEmailMessage: (message: EmailMessage, companySettings: OrganizerCompanySettings, isSetDefault: boolean)
        : AppThunkAction<KnownActions> => (dispatch, getState) => {
            dispatch({ type: actionTypes.ORGANIZER_EMAIL_MESSAGE_LOADER, loading: true });

            fetch(`${apiPrefix}EmailMessage`, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(message),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then(handleResponse)
                .then((newId) => {
                    message.id = newId;
                    let isRescheduleReminders = false;
                    if (isSetDefault) {
                        switch (message.type) {
                            case EmailMessageType.InitialEmail:
                                companySettings.defaultSettings.initialEmail = newId;
                                break;
                            case EmailMessageType.EngagementReminderEmail:
                                companySettings.defaultSettings.engLetterReminderEmail = newId;
                                isRescheduleReminders = true;
                                break;
                            case EmailMessageType.DownloadEngagementLetterEmail:
                                companySettings.defaultSettings.downloadEngagementLetterEmail = newId;
                                break;
                            case EmailMessageType.OrganizerReminderEmail:
                                companySettings.defaultSettings.organizerReminderEmail = newId;
                                isRescheduleReminders = true;
                                break;
                            case EmailMessageType.SourceDocReminderEmail:
                                companySettings.defaultSettings.sourceDocReminderEmail = newId;
                                break;
                        }
                        const action: any = ActionCreators.updateOrganizerCompanySettings(companySettings);
                        dispatch(action);
                    }
                    dispatch({
                        type: actionTypes.SAVE_ORGANIZER_EMAIL_MESSAGE, save: message
                    });

                    if (isRescheduleReminders) {
                        let globalAutoReminderRequest: GlobalOperationAutoReminderRequest = {
                            emailMessageType: message.type
                        };
                        const action: any = ActionCreators.updateGlobalAutomaticReminder(globalAutoReminderRequest);
                        dispatch(action);
                    }
                    
                    dispatch({ type: actionTypes.ORGANIZER_EMAIL_MESSAGE_LOADER, loading: false });
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Success.SaveEmailMessage,
                        statusType: StatusType.Success
                    });
                }).catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.SaveEmailMessage, statusType: StatusType.Error, statusCode: error?.status
                    });
                    dispatch({ type: actionTypes.ORGANIZER_EMAIL_MESSAGE_LOADER, loading: false });
                    logger?.trackError(`saveEmailMessage failed for the request having parameters ${JSON.stringify(message)} with error ${error.message}`)
                });
            
        },

    updateEmailMessage: (message: EmailMessage): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_EMAIL_MESSAGE_LOADER, loading: true });

        fetch(`${apiPrefix}EmailMessage`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.UPDATE_ORGANIZER_EMAIL_MESSAGE, update: message
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.UpdateEmailMessage,
                    statusType: StatusType.Success
                });
                dispatch({ type: actionTypes.ORGANIZER_EMAIL_MESSAGE_LOADER, loading: false });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.UpdateEmailMessage, statusType: StatusType.Error, statusCode: error?.status
                });
                logger?.trackError(`updateEmailMessage failed for the request having parameters ${JSON.stringify(message)}  with error ${error.message}`)
            });
        
    },

    deleteEmailMessage: (message: EmailMessage): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_EMAIL_MESSAGE_LOADER, loading: true });

        fetch(`${apiPrefix}EmailMessage`, {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.DELETE_ORGANIZER_EMAIL_MESSAGE, delete: message
                });
                dispatch({ type: actionTypes.ORGANIZER_EMAIL_MESSAGE_LOADER, loading: false });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.DeleteEmailMessage,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.DeleteEmailMessage,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`deleteEmailMessage failed for the request having parameters ${JSON.stringify(message)} with error ${error.message}`)
            });
        
    }
}

export const reducer: Reducer<OrganizerEmailMessageStoreState> = (state: OrganizerEmailMessageStoreState = unloadedOrganizerEmailMessageStore, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.ORGANIZER_EMAIL_MESSAGE_LOADER:
            return {
                ...state,
                loading: action.loading
            };

        case actionTypes.RECEIVE_ORGANIZER_EMAIL_MESSAGES:
            return {
                emailMessages: action.data,
                loading: action.loading
            };

        case actionTypes.DELETE_ORGANIZER_EMAIL_MESSAGE:
            let deleteMessage = { ...state }
            deleteMessage.emailMessages = deleteMessage.emailMessages.filter(message => message.id !== action.delete.id);
            return deleteMessage;

        case actionTypes.UPDATE_ORGANIZER_EMAIL_MESSAGE:
            let updateMessage = { ...state };
            updateMessage.emailMessages = updateMessage.emailMessages.map(message => {
                if (message.id === action.update.id)
                    return {
                        ...action.update
                    }
                return message;
            });
            return updateMessage;

        case actionTypes.SAVE_ORGANIZER_EMAIL_MESSAGE:
            let addedMessage = { ...state };
            addedMessage.emailMessages.push(action.save);
            return addedMessage;
    }
    return state || unloadedOrganizerEmailMessageStore;
};