import { actionTypes } from './../../store/ActionTypes';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './../../store';
import { handleResponse } from './../../store/Library';
import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import { IColumnSettings, OrganizerFilterType } from './../models/OrganizerComponentModels';
import { apiPrefix, ColumnSettingsInitialState, IColumnSettingsState, RecieveColumnSettingsAction } from './../models/OrganizerStoreModels';
import { LoggerFactory } from '../../Logger/LoggerFactory';
const logger = new LoggerFactory().getTelemetryLogger();

type KnownActions =
    RecieveColumnSettingsAction |
    NotificationAction;


export const actionCreators = {
    requestColumnSettings: (reportType: OrganizerFilterType): AppThunkAction<KnownActions> => (dispatch, getState) => {
        fetch(`${apiPrefix}ReportColumnSettings/` + reportType, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as IColumnSettings[])
            .then(data => {
                if (Object.keys(data).length === 0) {
                    data = [];
                }
                dispatch({
                    type: actionTypes.RECEIVE_DELIVERED_ORGANIZER_COLUMN_SETTINGS,
                    data: data,
                    reportType: reportType
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`requestColumnSettings failed for reportType: ${reportType}, with error ${error.message}`);
            });
        
    },
    saveColumnSettings: (reportType: OrganizerFilterType, data: IColumnSettings[], callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        fetch(`${apiPrefix}ReportColumnSettings/` + reportType, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, *',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(data)
        })
            .then(handleResponse)
            .then(response => response)
            .then(res => {
                dispatch({
                    type: actionTypes.RECEIVE_DELIVERED_ORGANIZER_COLUMN_SETTINGS,
                    data: data,
                    reportType: reportType
                });

                if (callback)
                    callback();
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`saveColumnSettings failed for reportType: ${reportType}, with error ${error.message}`);
            });
        
    }
};

export const reducer: Reducer<IColumnSettingsState> = (state: IColumnSettingsState = ColumnSettingsInitialState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.RECEIVE_DELIVERED_ORGANIZER_COLUMN_SETTINGS:
            switch (action.reportType) {
                case OrganizerFilterType.DeliveredOrganizers:
                    return {
                        ...state,
                        deliveredOrganizersColumnSettings: action.data
                    };
            }
            break;
    }
    return state || ColumnSettingsInitialState;
};