import { Action, Reducer } from 'redux';
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';
import { FinishProcessReturn } from '../../components/helper/Constants';
import { openWindowWithPostRequest } from '../../components/helper/HelperFunctions';
import { HideLoader } from '../../components/helper/Loader';
import { IFileClient } from '../../Core/Services/FileClient';
import { IPdfDocumentFacade, PdfDocumentFacade } from '../../Core/Utilities/PdfDocumentFacade';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import {
    AdditionalEsignModel,
    BatchCSVData, BatchModel, BatchStatus,
    OrganizerClient, OrganizerClientType, OrganizerDocument
} from '../models/OrganizerComponentModels';
import { container } from './../../Startup/inversify.config';
import { TYPES } from './../../Startup/types';
import { AppThunkAction } from './../../store';
import { actionTypes } from './../../store/ActionTypes';
import { NotificationAction, StatusType } from './../../store/common/NotificationStore';
import { handleBlobwithFileName, handleResponse } from './../../store/Library';
import { StoreConstants } from './../components/Helper/OrganizerConstants';
import {
    apiPrefix, BatchCsvLoaderAction, BatchOrganizerPageLoaderAction, BatchPageLoaderAction,
    BatchPopupLoaderAction, BatchStoreState, BatchTableModel, ClearPagedBatchAction, ClearPagedBatchOrganizerAction,
    DeliverBatchAction, initialBatchStoreState, OrganizerTableModel, ReceiveBatchAdditionalEsignPdfAction, ReceiveBatchOrganizerPdfDocumentAction, ReceiveBatchPdfAction, RecieveBatchAction, RecieveBatchOrganizerAction, RecieveBatchOrganizerDocumentClientAction,
    RecieveBatchOrganizerErrorAction, RecieveBatchOrganizersAction, RecieveOrganizerBatchAction, RecievePagedBatchOrganizerAction, RecievePagedOrganizerBatchAction, UpdateBatchInfoAction, UpdateBatchOrganizerErrorAction, UpdateBatchStatus
} from './../models/OrganizerStoreModels';
import { convertToJSON } from 'src/components/common/utils';
const logger = new LoggerFactory().getTelemetryLogger();



let expandedOrganizersAbortController = new AbortController();
const fileClient = container.get<IFileClient>(TYPES.IFileClient);

type KnownActions =
    UpdateBatchInfoAction |
    DeliverBatchAction |
    ClearPagedBatchOrganizerAction |
    UpdateBatchOrganizerErrorAction |
    ReceiveBatchPdfAction |
    BatchCsvLoaderAction |
    RecieveBatchAction |
    RecieveBatchOrganizerErrorAction |
    BatchOrganizerPageLoaderAction |
    BatchPageLoaderAction |
    RecievePagedBatchOrganizerAction |
    RecieveOrganizerBatchAction |
    RecieveBatchOrganizerAction |
    RecievePagedOrganizerBatchAction |
    RecieveBatchOrganizersAction |
    ReceiveBatchOrganizerPdfDocumentAction |
    RecieveBatchOrganizerDocumentClientAction |
    BatchPopupLoaderAction |
    ReceiveBatchAdditionalEsignPdfAction |
    NotificationAction |
    UpdateBatchStatus |
    ClearPagedBatchAction;
const validateTaxpayerPreview = (validateUrl: string, cpaToken: string): AppThunkAction<KnownActions> => (dispatch, getstate) => {
    const data = {
        token: cpaToken,
        scope: 'preview'
    }
    fetch(`${validateUrl}`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'
        }
    })
        .then(response => response.json())
        .then((result) => {
            dispatch({ type: actionTypes.NOTIFICATION, statusMessage: FinishProcessReturn.StatusMessage.GenerateTaxpayerViewSuccess, statusType: StatusType.Success });
            window.open(result.url, '_blank');
        })
        .catch((error) => {
            HideLoader();
            dispatch({ type: actionTypes.NOTIFICATION, statusMessage: FinishProcessReturn.StatusMessage.GenerateTaxpayerClientViewError, statusType: StatusType.Error, statusCode: error?.status });
        });

}
export const actionCreator = {
    requestBatches: (query: string, reload = false, callback?: any): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.BATCH_PAGE_LOADER,
            loading: true
        });

        const appState = getState();

        if (reload) {
            dispatch({
                type: actionTypes.CLEAR_PAGED_BATCHES
            });
        } else {
            const pagedBatch = appState.pagedBatchStore.batches[query];
            if (pagedBatch) {
                dispatch({
                    type: actionTypes.RECEIVE_ORGANIZER_BATCHES,
                    batchModel: pagedBatch
                });

                dispatch({
                    type: actionTypes.BATCH_PAGE_LOADER,
                    loading: false
                });
                return;
            }
        }
        fetch(`${apiPrefix}BatchReport/${query}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as BatchTableModel)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_ORGANIZER_BATCHES,
                    batchModel: data
                });
                dispatch({
                    type: actionTypes.RECEIVE_PAGED_ORGANIZER_BATCHES,
                    batchModel: data,
                    query: query
                });
                dispatch({
                    type: actionTypes.BATCH_PAGE_LOADER,
                    loading: false
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.AllOrganizers,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`requestBatches failed for the request having parameters ${query} with error ${error.message}`)
            });
    },

    exportBatchOrganizersAsExcel: (query: string, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        fetch(`${apiPrefix}BatchReport/ExportExcel/${query}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => {
                if (callback) {
                    callback();
                }
            }).catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.Export, statusType: StatusType.Error, statusCode: error?.status });
                if (callback) {
                    callback();
                }
                logger?.trackError(`exportBatchOrganizersAsExcel failed for the request having parameters ${query} with error ${error.message}`)
            });
    },

    requestBatchOrganizers: (batchGuid: string, query: string, isDeleteRequest: boolean = false, countCallback?: (count: number) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.BATCH_ORGANIZER_PAGE_LOADER,
            loading: true
        });

        if (!isDeleteRequest) {
            const appState = getState();
            expandedOrganizersAbortController.abort();
            expandedOrganizersAbortController = new AbortController();
            const pagedBatchOrganizers = appState.pagesBatchOrganizerStore.organizers[query];
            if (pagedBatchOrganizers) {
                dispatch({
                    type: actionTypes.RECEIVE_BATCH_ORGANIZERS,
                    organizerModel: pagedBatchOrganizers,
                    batchGuid: batchGuid
                });

                dispatch({
                    type: actionTypes.BATCH_ORGANIZER_PAGE_LOADER,
                    loading: false
                });
                return;
            }
        } else {
            dispatch({
                type: actionTypes.CLEAR_PAGED_BATCH_ORGANIZERS
            });
        }

        fetch(`${apiPrefix}BatchReport/GetBatchOrganizers/${query}`, {
            method: 'GET',
            credentials: 'include',
            signal: expandedOrganizersAbortController.signal
        })
            .then(handleResponse)
            .then(response => response as OrganizerTableModel)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_BATCH_ORGANIZERS,
                    organizerModel: data,
                    batchGuid: batchGuid
                });
                dispatch({
                    type: actionTypes.RECEIVE_PAGED_BATCH_ORGANIZERS,
                    organizers: data,
                    query: query
                });
                dispatch({
                    type: actionTypes.BATCH_ORGANIZER_PAGE_LOADER,
                    loading: false
                });
                countCallback && countCallback(data.count);
            })
            .catch((error) => {
                if (error.name === 'AbortError') {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.AllOrganizers,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`requestBatchOrganizers GetBatchOrganizers failed for the request having parameters ${query} with error ${error.message}`)
            });
    },


    requestBatchOrganizer: (id: number, batchGuid: string, callback?: (document: OrganizerDocument) => void, failureCallback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
       fetch(`${apiPrefix}Batch/GetBatchOrganizer/${batchGuid}/${id}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as OrganizerDocument)
            .then(data => {
                if (!data.id) {
                    throw Error();
                }
                data.formGroups = data.formGroups.filter(formGroup => formGroup);
                dispatch({
                    type: actionTypes.RECEIVE_BATCH_ORGANIZER,
                    batchGuid: batchGuid,
                    organizer: data
                });
                callback && callback(data);
            })
            .catch((error) => {
                failureCallback && failureCallback();
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.Organizer,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`requestBatchOrganizer GetBatchOrganizer failed for the request having parameters ${batchGuid}/${id} with error ${error.message}`)
            });
    },

    requestBatchOrganizerClients: (id: number, batchGuid: string, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.BATCH_POPUP_LOADER,
            loading: true
        });
        fetch(`${apiPrefix}Details/Clients/${id}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as OrganizerClient[])
            .then((data) => {
                dispatch({
                    type: actionTypes.RECEIVE_BATCH_ORGANIZER_DOCUMENT_CLIENTS,
                    clients: data,
                    batchGuid: batchGuid,
                    id: id
                });
                dispatch({
                    type: actionTypes.BATCH_POPUP_LOADER,
                    loading: false
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.OrganizerClients,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`requestBatchOrganizerClients Clients failed for the request having parameters ${id} with error ${error.message}`)
            });
    },

    requestBatchPdf: (documentGuid: string, batchGuid: string, year: number): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const appState = getState();
        const batch = appState.batchStore.batchModel.batches.find(batch => batch.id === batchGuid);
        if (batch) {
            if (!batch.organizerPdf) {
                fetch(`${apiPrefix}Document/GetDocumentLink/${documentGuid}/${year}`,
                    {
                        method: 'GET',
                        credentials: 'include'
                    })
                    .then(handleResponse)
                    .then(response => response as string)
                    .then((documentUrl) => {
                        fileClient.download(documentUrl)
                            .then((data: any) => {
                                const document: IPdfDocumentFacade = new PdfDocumentFacade();
                                document.append(data, documentUrl);
                                dispatch({
                                    type: actionTypes.RECEIVE_BATCH_PDF,
                                    document: document,
                                    batchGuid: batchGuid
                                });
                            }, () => {
                                dispatch({
                                    type: actionTypes.NOTIFICATION,
                                    statusMessage: StoreConstants.Failure.OrganizerPdf,
                                    statusType: StatusType.Error,
                                })
                            });
                    }).catch((error) => {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: StoreConstants.Failure.OrganizerPdf,
                            statusType: StatusType.Error,
                            statusCode: error?.status
                        })
                        logger?.trackError(`requestBatchPdf failed for the request having parameters ${documentGuid}/${year} with error ${error.message}`)
                    });
            }
        }
    },

    requestBatchOrganizerPdfDocument: (documentGuid: string, batchGuid: string, documentId: number, year: number): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const appState = getState();
        const batch = appState.batchStore.batchModel.batches.find(batch => batch.id === batchGuid);
        if (batch) {
            const document = batch.organizers.documents.find(document => document.id === documentId);
            if (document && !document.document) {
                 fetch(`${apiPrefix}Document/GetDocumentLink/${documentGuid}/${year}`,
                    {
                        method: 'GET',
                        credentials: 'include'
                    })
                    .then(handleResponse)
                    .then(response => response as string)
                    .then((documentUrl) => {
                        fileClient.download(documentUrl)
                            .then((data: any) => {
                                const document: IPdfDocumentFacade = new PdfDocumentFacade();
                                document.append(data, documentUrl);
                                dispatch({
                                    type: actionTypes.RECEIVE_BATCH_ORGANIZER_PDF,
                                    id: documentId, document: document,
                                    batchGuid: batchGuid
                                });
                            }, () => {
                                dispatch({
                                    type: actionTypes.NOTIFICATION,
                                    statusMessage: StoreConstants.Failure.OrganizerPdf,
                                    statusType: StatusType.Error,
                                })
                            });
                    }).catch((error) => {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: StoreConstants.Failure.OrganizerPdf,
                            statusType: StatusType.Error,
                            statusCode: error?.status
                        })
                        logger?.trackError(`requestBatchOrganizerPdfDocument failed for the request having parameters ${documentGuid}/${year} with error ${error.message}`)
                    });
                
            }
        }
    },

    requestBatch: (batchGuid: string, callback: (batch: BatchModel) => void, failureCallback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {

         fetch(`${apiPrefix}Batch/GetBatch/${batchGuid}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as BatchModel)
            .then(data => {
                if (!data.id) {
                    throw Error();
                }
                dispatch({
                    type: actionTypes.RECEIVE_ORGANIZER_BATCH,
                    batch: data
                });
                callback(data);
            })
            .catch((err) => {
                failureCallback && failureCallback();
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.Batch,
                    statusType: StatusType.Error,
                    statusCode: err?.status
                });
            })
        
    },

    updateBatch: (model: BatchModel, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
         fetch(`${apiPrefix}Batch/SaveBatch`, {
            method: 'POST',
            credentials: 'include',
            body: convertToJSON(model),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.SaveOrganizer,
                    statusType: StatusType.Success
                });
                dispatch({
                    type: actionTypes.RECEIVE_ORGANIZER_BATCH,
                    batch: model
                });
                callback();
            })
            .catch((err) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.SaveOrganizer,
                    statusType: StatusType.Error,
                    statusCode: err?.status
                });
            });
        
    },

    deliverBatch: (model: BatchModel, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {

         fetch(`${apiPrefix}Batch/DeliverBatch`, {
            method: 'POST',
            credentials: 'include',
            body: convertToJSON(model),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.BatchDeliver,
                    statusType: StatusType.Success
                });
                dispatch({
                    type: actionTypes.DELIVER_BATCH,
                    batch: model
                });
                callback();
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.BatchDeliver,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`deliverBatch failed to push the data  ${JSON.stringify({ ...model, organizerPdf: null, additionalQuestionsPdf: null, organizers: [], status: BatchStatus.DeliveryInProgress })} with error ${error.message}`)
            });
        
    },

    requestBatchError: (page: number, pageSize: number, batchGuid: string, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.BATCH_CSV_LOADER,
            loading: true
        });
         fetch(`${apiPrefix}Batch/GetCSVData/${batchGuid}/${page}/${pageSize}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as BatchCSVData[])
            .then((data) => {
                dispatch({
                    type: actionTypes.RECEIVE_ORGANIZER_BATCH_ERROR,
                    errors: data,
                    batchGuid: batchGuid,
                    page: page
                });
                dispatch({
                    type: actionTypes.BATCH_CSV_LOADER,
                    loading: false
                });
                callback && callback();
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.OrganizerClients,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`requestBatchError failed for the request having parameters ${batchGuid}/${page}/${pageSize} with error ${error.message}`)
            });
        
    },

    updateBatchError: (csvData: BatchCSVData[], batchGuid: string, csvWithErrorData: BatchCSVData[], callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.BATCH_CSV_LOADER,
            loading: true
        });
         fetch(`${apiPrefix}Batch/UpdateCSVData`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(csvData),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.UPDATE_ORGANIZER_BATCH_ERROR,
                    batchGuid: batchGuid,
                    csvWithErrorData: csvWithErrorData
                });

                dispatch({
                    type: actionTypes.BATCH_CSV_LOADER,
                    loading: false
                });

                callback && callback();
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.OrganizerClients,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`updateBatchError failed for the request having parameters ${JSON.stringify(csvData)} with error ${error.message}`)
            });
        
    },


    deleteBatch: (batchId: string, successcallback: () => void, failedcallback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
         fetch(`${apiPrefix}Batch/${batchId}`, {
            method: 'DELETE',
            credentials: 'include'
        })
            .then((responseJson) => {
                if (responseJson.ok) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Success.DeleteBatch,
                        statusType: StatusType.Success
                    });
                    successcallback();
                }
                else {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Failure.DeleteBatch,
                        statusType: StatusType.Error,
                    });
                }
                failedcallback();
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.DeleteBatch,
                    statusType: StatusType.Error,
                    statusCode: error?.status,
                });
                logger?.trackError(`deleteBatch failed for batchId: ${batchId}, with error ${error.message}`);
                failedcallback();
            });
        
    },

    deleteOrganizerFromBatch: (batchId: string, documentId: number, successcallback: () => void, failedcallback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.BATCH_ORGANIZER_PAGE_LOADER,
            loading: true
        });
         fetch(`${apiPrefix}Batch/${batchId}/organizers/${documentId}`, {
            method: 'DELETE',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response.status as BatchStatus)
            .then((status) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.DeleteBatchOrganizer,
                    statusType: StatusType.Success
                });
                dispatch({
                    type: actionTypes.UPDATE_BATCH_STATUS,
                    batchId: batchId,
                    batchStatus: status
                })
                dispatch({
                    type: actionTypes.BATCH_ORGANIZER_PAGE_LOADER,
                    loading: false
                });
                successcallback();

            }).catch((error) => {
                dispatch({
                    type: actionTypes.BATCH_ORGANIZER_PAGE_LOADER,
                    loading: false
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.DeleteBatchOrganizer,
                    statusType: StatusType.Error,
                    statusCode: error?.status,
                });
                logger?.trackError(`deleteOrganizerFromBatch failed for batchId: ${batchId} and organizerId: ${documentId}, with error ${error.message}`);
                failedcallback();
            });
        
    },

    updateBatchInfo: (batchName: string, batchGuid: string, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
         fetch(`${apiPrefix}Batch/UpdateBatchInfo/${encodeURIComponent(batchName)}/${batchGuid}`, {
            method: 'POST',
            credentials: 'include'
        })
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.BatchInfo,
                    statusType: StatusType.Success
                });
                dispatch({
                    type: actionTypes.UPDATE_BATCH_INFO,
                    batchGuid: batchGuid,
                    batchName: batchName
                })
                callback();
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.BatchInfo,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`updateBatchInfo failed for the request having parameters ${batchName}/${batchGuid} with error ${error.message}`)
            });
        
    },

    updateOrganizerInBatch: (model: OrganizerDocument, callback: (id: number) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
         fetch(`${apiPrefix}Organizer/SaveOrganizer`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({ ...model, document: null, questionsDocument: null }),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.SaveOrganizer,
                    statusType: StatusType.Success
                });

                callback(model.id);
            })
            .catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.SaveOrganizer, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`updateOrganizer failed for the request having parameters ${JSON.stringify({ ...model, document: null, questionsDocument: null })} with error ${error.message}`)
            });
        
    },

    checkErrors: (batchGuid: string, callback: (errors: boolean) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
         fetch(`${apiPrefix}Batch/CheckErrors/${batchGuid}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as boolean)
            .then((data) => {
                callback(data);
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.Error,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`CheckErrors failed for the request having parameters ${batchGuid} with error ${error.message}`)
                callback(true);
            });
        
    },

    checkWarnings: (batchGuid: string, callback: (errors: boolean) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
         fetch(`${apiPrefix}Batch/CheckWarnings/${batchGuid}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as boolean)
            .then((data) => {
                callback(data);
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.Error,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                callback(true);
                logger?.trackError(`CheckWarnings failed for the request having parameters ${batchGuid} with error ${error.message}`)
            });
        
    },

    downloadCSVTemplate: (callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}Document/GetCSVTemplate/`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleBlobwithFileName)
            .then((data) => {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data.blob, `Batch_CSV_Template.pdf`);
                callback();
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.CSVTemplate,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
            });
        
    },

    generateBatchTaxPayerView: (model: BatchModel, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
         fetch(`${apiPrefix}Preview/BatchTaxPayerPreview`, {
            method: 'POST',
            credentials: 'include',
            body: convertToJSON(model),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(response => response.json())
            .then((data) => {
                dispatch({
                    type: actionTypes.RECEIVE_ORGANIZER_BATCH,
                    batch: JSON.parse(data.batchModel) as BatchModel
                });
                dispatch(validateTaxpayerPreview(data.url, data.token));
                callback();
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: FinishProcessReturn.StatusMessage.GenerateTaxpayerViewError,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                callback();
                logger?.trackError(`generateBatchTaxPayerView failed for the request having batchId/processInfoId: ${model.id}/${model.processInfoId} with error ${error.message}`)
            });
        
    },

    generateBatchIndividualTaxPayerView: (model: any, batchId: string, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let payload = { processInfo: model.processInfo, questionnaireVM: model.questionnaireVM, batchId: batchId };
         fetch(`${apiPrefix}Preview/BatchIndividualTaxPayerPreview`, {
            method: 'POST',
            credentials: 'include',
            body: convertToJSON(payload),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(response => response.json())
            .then((data) => {
                dispatch(validateTaxpayerPreview(data.url, data.token));
                callback();
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: FinishProcessReturn.StatusMessage.GenerateTaxpayerViewError,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                callback();
                logger?.trackError(`generateBatchIndividualTaxPayerView failed for the request having batchId/processInfoId: ${batchId}/${model.id} with error ${error.message}`)
            });
        
    },

    generateBatchIndividualTaxPayerClientView: (model: OrganizerDocument, clientType: OrganizerClientType, batchId: string, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
         fetch(`${apiPrefix}Preview/BatchIndividualTaxPayerClientView`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                processInfo: { ...model, document: null, questionsDocument: null },
                batchId: batchId,
                clientType: clientType
            }),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(response => response.json())
            .then((data) => {
                HideLoader();
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: FinishProcessReturn.StatusMessage.GenerateTaxpayerClientViewSuccess,
                    statusType: StatusType.Success
                });
                openWindowWithPostRequest(data.url, data.token, 'CPAToken', 'client_view');
                callback();
            }).catch((error) => {
                HideLoader();
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: FinishProcessReturn.StatusMessage.GenerateTaxpayerClientViewError,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                callback();
                logger?.trackError(`BatchIndividualTaxPayerClientView failed for the request having batchId/processInfoId: ${batchId}/${model.id} with error ${error.message}`)
            });
        
    },

    requestAdditionalEsignDocsBatch: (documentGuid: string, taxYear: number, callback?: (esignDocs: AdditionalEsignModel[]) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}Batch/GetBatchAdditionalEsignDocuments/${documentGuid}/${taxYear}`, { credentials: 'include' })
            .then(handleResponse)
            .then(response => response as AdditionalEsignModel[])
            .then(function (data) {
                dispatch({ type: actionTypes.RECEIVE_BATCH_ADDITIONAL_ESIGN_PDF, additionalEsignDocuments: data, id: documentGuid });
                if (callback) {
                    callback(data);
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.AdditionalEsignFetchFailed,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                })
                logger?.trackError(`requestAdditionalEsignDocsBatch failed for the request having parameters with error ${error.message}`)
            });
        
    },

    deleteBatchAdditionalEsignDocs: (documentIds: number[], callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getstate) => {
         fetch(`${apiPrefix}Batch/DeleteAdditionalEsignDocument`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(documentIds),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then((data) => {
                callback && callback();
            })
            .catch((error) => {
                logger?.trackError(`deleteAdditionalEsignDocs failed for the request having parameters ${documentIds} with error ${error.message}`);

            });

        
    },
};

export const reducer: Reducer<BatchStoreState> = (state: BatchStoreState = initialBatchStoreState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.RECEIVE_ORGANIZER_BATCHES: {
            const batches = { ...state };
            batches.batchModel = action.batchModel;
            return batches;
        }
        case actionTypes.BATCH_ORGANIZER_PAGE_LOADER: {
            const organizerLoader = { ...state };
            organizerLoader.organizerTableLoading = action.loading;
            return organizerLoader;
        }
        case actionTypes.BATCH_POPUP_LOADER: {
            const popupLoader = { ...state };
            popupLoader.popupLoader = action.loading;
            return popupLoader;
        }
        case actionTypes.BATCH_PAGE_LOADER: {
            const batchLoader = { ...state };
            batchLoader.loading = action.loading;
            return batchLoader;
        }
        case actionTypes.RECEIVE_BATCH_ORGANIZERS: {
            const batchOrganizers = { ...state };
            batchOrganizers.batchModel.batches = batchOrganizers.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers = action.organizerModel
                }
                return batch;
            });
            return batchOrganizers;
        }

        case actionTypes.UPDATE_BATCH_INFO: {
            const batchInfo = { ...state };
            batchInfo.batchModel.batches = batchInfo.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.batchName = action.batchName
                }
                return batch;
            });
            return batchInfo;
        }

        case actionTypes.BATCH_CSV_LOADER: {
            const csvLoader = { ...state };
            csvLoader.csvLoader = action.loading;
            return csvLoader;
        }


        case actionTypes.RECEIVE_ORGANIZER_BATCH: {
            const batch = { ...state };
            batch.batchModel.batches = batch.batchModel.batches.map(x => {
                if (x.id === action.batch.id) {
                    return { ...x, ...action.batch };
                }
                return x;
            });
            return batch;
        }


        case actionTypes.DELIVER_BATCH: {
            const batch = { ...state };
            batch.batchModel.batches = batch.batchModel.batches.map(x => {
                if (x.id === action.batch.id) {
                    return { ...x, ...action.batch, status: BatchStatus.DeliveryInProgress };
                }
                return x;
            });
            return batch;
        }

        case actionTypes.RECEIVE_BATCH_ORGANIZER: {
            const receiveOrganizer = { ...state };
            receiveOrganizer.batchModel.batches = receiveOrganizer.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers.documents = batch.organizers.documents.map(org => {
                        if (org.id === action.organizer.id) {
                            const taxpayer = action.organizer.organizerClients.find(client => client.clientType === OrganizerClientType.Taxpayer);
                            return {
                                ...action.organizer,
                                taxpayerName: taxpayer ? taxpayer.name : org.taxpayerName,
                                document: org.document,
                                questionsDocument: org.questionsDocument
                            }
                        }
                        return org;
                    });
                }
                return batch;
            });
            return receiveOrganizer;
        }

        case actionTypes.RECEIVE_BATCH_ORGANIZER_DOCUMENT_CLIENTS: {
            const clients = { ...state };
            clients.batchModel.batches = clients.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers.documents = batch.organizers.documents.map(org => {
                        if (org.id === action.id) {
                            return {
                                ...org,
                                organizerClients: action.clients
                            }
                        }
                        return org;
                    });
                }
                return batch;
            });
            return clients;
        }

        case actionTypes.RECEIVE_BATCH_ORGANIZER_PDF: {
            const pdfDocument = { ...state };

            pdfDocument.batchModel.batches = pdfDocument.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers.documents = batch.organizers.documents.map(org => {
                        if (org.id === action.id) {
                            return {
                                ...org,
                                document: action.document,
                                questionsDocument: org.questionsDocument
                            }
                        }
                        return org;
                    });
                }
                return batch;
            });
            return pdfDocument;
        }

        case actionTypes.RECEIVE_BATCH_ADDITIONAL_ESIGN_PDF: {
            const pdfDocument = { ...state };

            pdfDocument.batchModel.batches = pdfDocument.batchModel.batches.map(batch => {
                if (batch.id === action.id) {
                    return {
                        ...batch,
                        additionalEsignDocuments: action.additionalEsignDocuments
                    }
                }
                return batch;
            });
            return pdfDocument;
        }

        case actionTypes.RECEIVE_BATCH_PDF: {
            const pdfDocument = { ...state };

            pdfDocument.batchModel.batches = pdfDocument.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizerPdf = action.document
                }
                return batch;
            });
            return pdfDocument;
        }

        case actionTypes.RECEIVE_ORGANIZER_BATCH_ERROR: {
            const errors = { ...state };

            errors.batchModel.batches = errors.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    if (!batch.csvData) {
                        batch.csvData = {};
                    }
                    if (action.errors) {
                        batch.csvData[action.page] = action.errors;
                    }
                }
                return batch;
            })
            return errors;
        }

        case actionTypes.UPDATE_ORGANIZER_BATCH_ERROR: {
            const errors = { ...state };

            errors.batchModel.batches = errors.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    if (action.csvWithErrorData.length == 0) {
                        batch.csvData = {}
                    }
                }
                return batch;
            })
            return errors;
        }

        case actionTypes.UPDATE_BATCH_STATUS: {
            const currentState = { ...state };

            currentState.batchModel.batches = currentState.batchModel.batches.map(batch => {
                if (batch.id === action.batchId) {
                    batch.status = action.batchStatus;
                }
                return batch;
            })
            return currentState;
        }

    }
    return state || initialBatchStoreState;
}