import { Reducer } from "react";
import { Action } from "redux";
import { sssuiteApiPrefix } from "src/components/helper/HelperFunctions";
import { AppThunkAction } from "src/store";
import { actionTypes } from "src/store/ActionTypes";
import { NotificationAction, StatusType } from "src/store/common/NotificationStore";
import { handleResponse } from "src/store/Library";


export interface ILinkedMergedDetail  {
    emailId: string;
    clientId: string;
    name: string;
    isPrimaryUTE: boolean;
    isSpouse: boolean;
};
export interface ILinkedMergedDetails {
    mergedDetails : ILinkedMergedDetail[],
    loading:boolean
}


export const initialMergedState:ILinkedMergedDetails = {
    mergedDetails :[],
    loading:false
}

export interface  ReuestMergeDetails {
    type: actionTypes.REQUEST_UTE_MERGED_DETAILS;
    loading : boolean;
}

export interface RecieveMergeDetails {
    type:actionTypes.RECEIVE_UTE_MERGED_DETAILS,
    mergedDetails : ILinkedMergedDetail[]
}

type KnownActions = ReuestMergeDetails | RecieveMergeDetails | NotificationAction;

export const actionCreators = {
    requestUteMergedDetails: (emailAddress: string, callback?: (data?: any) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.REQUEST_UTE_MERGED_DETAILS, loading:true});

            fetch(`${sssuiteApiPrefix}/one-hub/linked-merge-details?emailId=${emailAddress}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then((data) => {
                    if (callback) {
                        callback(data);
                    }
                    
                    dispatch({ type: actionTypes.RECEIVE_UTE_MERGED_DETAILS, mergedDetails:data});
                    dispatch({ type: actionTypes.REQUEST_UTE_MERGED_DETAILS, loading:false});
                }).catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error.statusText,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                });
            
        
       
    }
}

export const reducer: Reducer<ILinkedMergedDetails,Action> = (
    state: ILinkedMergedDetails = initialMergedState,
    incomingAction:Action
  ) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
      case actionTypes.RECEIVE_UTE_MERGED_DETAILS: {
        const uteMergeState = { ...state };
        uteMergeState.mergedDetails = action.mergedDetails;
        return uteMergeState;
      }
      case actionTypes.REQUEST_UTE_MERGED_DETAILS: {
        return {
          ...state,
           loading: action.loading,
        };
      }
    }
    return state || initialMergedState;
  };
