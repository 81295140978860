import { AppThunkAction } from "./../../store";
import { actionTypes } from "./../../store/ActionTypes";
import { NotificationAction, StatusType } from "./../../store/common/NotificationStore";
import { handleResponse, handleBlobwithFileName } from "./../../store/Library";
import { Action, Reducer } from "redux";
import { StoreConstants } from "../components/Helper/OrganizerConstants";
import {
  apiPrefix,
  BatchStoreState,
  initialBatchStoreState,
  BatchTableModel,
  RecieveUndeliveredOrganizerBatchAction,
  UndeliveredBatchPageLoaderAction,
  UndeliveredBatchPopupLoaderAction,
} from "./../models/OrganizerStoreModels";

import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';

type KnownActions =
  | RecieveUndeliveredOrganizerBatchAction
  | UndeliveredBatchPageLoaderAction
  | UndeliveredBatchPopupLoaderAction
  | NotificationAction;

export const actionCreators = {
  requestUndeliveredBatch:
    (query: string, callback?: any): AppThunkAction<KnownActions> =>
    (dispatch, getState) => {
      dispatch({
        type: actionTypes.UNDELIVERED_BATCH_PAGE_LOADER,
        loading: true,
      });

      fetch(
        `${apiPrefix}BatchUndeliveredOrganizer/GetBatchUndeliveredReport${query}`,
        {
          method: "GET",
          credentials: "include",
        }
      )
        .then(handleResponse)
        .then((response) => response as BatchTableModel)
        .then((data) => {
          dispatch({
            type: actionTypes.RECEIVE_UNDELIVERED_ORGANIZER_BATCHES,
            batchModel: data,
          });

          dispatch({
            type: actionTypes.UNDELIVERED_BATCH_PAGE_LOADER,
            loading: false,
          });
          if (callback) {
            callback();
          }
        })
        .catch((error) => {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: StoreConstants.Failure.AllOrganizers,
              statusType: StatusType.Error,
              statusCode: error?.status
          });
        });
      
    },
  exportReportToExcel:
    (query: string, callback?: any): AppThunkAction<KnownActions> =>
    (dispatch, getState) => {
      fetch(
        `${apiPrefix}BatchUndeliveredOrganizer/ExportExcel${query}`,
        {
          method: "GET",
          credentials: "include",
        }
      )
      .then(response => handleBlobwithFileName(response))            
      .then(data => {           
          let displayDownloadFile = new DisplayDownloadFile();
          displayDownloadFile.showFile(data.blob,data.filename);  
          if(callback)callback();
      })
        .catch(() => {
          if (callback) {
            callback();
          }
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: StoreConstants.Failure.Export,
            statusType: StatusType.Error,
          });
        });
      
    },
  requestUndeliverdOrganizerCsv:
    (
      batchId: string,
      batchName: string,
      taxYear: number,
      callback?: any
    ): AppThunkAction<KnownActions> =>
    (dispatch, getState) => {
      dispatch({
        type: actionTypes.UNDELIVERED_BATCH_POPUP_LOADER,
        loading: true,
      });

      fetch(
        `${apiPrefix}BatchUndeliveredOrganizer/GetUndeliveredCsvAsync/${batchId}?batchName=${batchName}&taxYear=${taxYear}`,
        {
          method: "GET",
          credentials: "include",
        }
      )
      .then(response => handleBlobwithFileName(response))
        .then((data) => {
          
          dispatch({
            type: actionTypes.UNDELIVERED_BATCH_POPUP_LOADER,
            loading: false,
          });
          
          let displayDownloadFile = new DisplayDownloadFile();
          displayDownloadFile.showFile(data.blob,data.filename); 

          if (callback) {
            callback();
          }
        })
        .catch(() => {
          if (callback) {
            callback();
          }
          dispatch({
            type: actionTypes.UNDELIVERED_BATCH_POPUP_LOADER,
            loading: false,
          });
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: StoreConstants.Failure.Export,
            statusType: StatusType.Error,
          });
        });
      
    },
  deleteUndeliverdBatchOrganizer:
    (batchId: string, callback?: any): AppThunkAction<KnownActions> =>
    (dispatch, getState) => {
      fetch(
        `${apiPrefix}BatchUndeliveredOrganizer/DeleteUndeliveredBatch/${batchId}`,
        {
          method: "GET",
          credentials: "include",
        }
      )
        .then(handleResponse)
        .then(() => {
          if (callback) {
            callback();
          }
        })
        .catch((error) => {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: StoreConstants.Failure.DeleteBatch,
            statusType: StatusType.Error,
            statusCode: error?.status
          });
        });
      
    },
};

export const reducer: Reducer<BatchStoreState> = (
  state: BatchStoreState = initialBatchStoreState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownActions;
  switch (action.type) {
    case actionTypes.RECEIVE_UNDELIVERED_ORGANIZER_BATCHES: {
      const batchState = { ...state };
      batchState.batchModel = action.batchModel;
      return batchState;
    }
    case actionTypes.UNDELIVERED_BATCH_PAGE_LOADER: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case actionTypes.UNDELIVERED_BATCH_POPUP_LOADER: {
      return {
        ...state,
        popupLoader: action.loading,
      };
    }
  }
  return state || initialBatchStoreState;
};
