import { Action, Reducer } from 'redux';
import { DeliveredReturnsConstants } from '../../components/helper/Constants';
import * as Helper from '../../components/helper/HelperFunctions';
import { IDialogBox } from '../../Core/Utilities/UI/DialogBox';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { container } from '../../Startup/inversify.config';
import { TYPES } from '../../Startup/types';
import { GetMyDownloadsList } from '../../store/company/CompanyStore';
import { AppThunkAction } from './../../store';
import { actionTypes } from './../../store/ActionTypes';
import { NotificationAction, StatusType } from './../../store/common/NotificationStore';
import { handleResponse } from './../../store/Library';
import { StoreConstants } from './../components/Helper/OrganizerConstants';
import { DownloadType, OrganizerCompanySettings } from './../models/OrganizerComponentModels';
import {
    apiPrefix, downloadBatchQueryFilters, DownloadBulkOrganizerAsync, DownloadOrganizerAsync, downloadOrganizerStoreState,
    DownloadOrganizerStoreState, downloadQueryFilters, SetDownloadBatchOrganizerAsync, SetDownloadBatchOrganzierAsyncLoading, SetDownloadBulkBatchOrganizerAsync, SetDownloadBulkBatchOrganzierAsyncLoading, SetDownloadBulkOrganizerAsync, SetDownloadBulkOrganizerAsyncLoading, SetDownloadOrganizerAsync, SetDownloadOrganizerAsyncLoading
} from './../models/OrganizerStoreModels';
const logger = new LoggerFactory().getTelemetryLogger();


type KnownActions =
    DownloadOrganizerAsync |
    SetDownloadOrganizerAsync |
    SetDownloadOrganizerAsyncLoading |
    DownloadBulkOrganizerAsync |
    SetDownloadBulkOrganizerAsync |
    SetDownloadBulkOrganizerAsyncLoading |
    SetDownloadBatchOrganizerAsync |
    SetDownloadBatchOrganzierAsyncLoading |
    SetDownloadBulkBatchOrganizerAsync |
    SetDownloadBulkBatchOrganzierAsyncLoading |
    NotificationAction |
    GetMyDownloadsList

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export const ActionCreators = {
    downloadOrganizerAsync: (apiUrl: string, query: downloadQueryFilters, documentIds: number[], selectedDownloadType: DownloadType, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_DOWNLOAD_ORGANIZER_ASYNC_LOADING,
            loading: true
        })
        fetch(apiPrefix + apiUrl, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                query,
                documentIds
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then((data: any) => {
                if (selectedDownloadType == DownloadType.DownloadNow) {
                    if (Helper.SessionStore.isExists("DownloadNowIds")) {
                        let DownloadNowIds = Helper.SessionStore.get("DownloadNowIds");
                        var ids = JSON.parse(DownloadNowIds) as string[];
                        ids.push(data.id)
                        Helper.SessionStore.set("DownloadNowIds", JSON.stringify(ids));
                    }
                    else {
                        let ids = [data.id];
                        Helper.SessionStore.set("DownloadNowIds", JSON.stringify(ids));
                    }
                }
                dispatch({
                    type: actionTypes.SET_DOWNLOAD_ORGANIZER_ASYNC,
                    downloadOrganizerAsyncResponse: data
                });
                dispatch({
                    type: actionTypes.SET_DOWNLOAD_ORGANIZER_ASYNC_LOADING,
                    loading: false
                })
                if (callback) {
                    callback();
                }
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.CompanySettings,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`removeOrganizerReportFilter failed for the request  with error ${error.message}`)
            });
        
    },

    downloadBulkOrganizerAsync: (apiUrl: string, query: downloadQueryFilters, selectedDownloadType: DownloadType, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const state = getState();
        dispatch({
            type: actionTypes.SET_DOWNLOAD_BULK_ORGANIZER_ASYNC_LOADING,
            loading: true
        })
        fetch(apiPrefix + apiUrl, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then((response: any) => {

                if (response.id == undefined) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileError,
                        statusType: StatusType.Error
                    });
                }
                else {
                    if (state.userSettings.settings.defaultUserSettings.download.rememberDefaultDownload == true) {
                        selectedDownloadType = state.userSettings.settings.defaultUserSettings.download.downloadType;
                    }
                    if (selectedDownloadType == DownloadType.DownloadNow) {
                        if (Helper.SessionStore.isExists("DownloadNowIds")) {
                            let DownloadNowIds = Helper.SessionStore.get("DownloadNowIds");
                            var ids = JSON.parse(DownloadNowIds) as string[];
                            ids.push(response.id)
                            Helper.SessionStore.set("DownloadNowIds", JSON.stringify(ids));
                        }
                        else {
                            let ids = [response.id];
                            Helper.SessionStore.set("DownloadNowIds", JSON.stringify(ids));
                        }
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileNowWaitProcessing,
                            statusType: StatusType.Success
                        });
                    }

                    else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileWaitProcessing,
                            statusType: StatusType.Success
                        });
                    }
                    let data = state.companyData.myDownloads;
                    data.unshift(response);
                    dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, myDownloadsList: data })

                }
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileError,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                if (callback) {
                    callback();
                }
            });
        
    },

    downloadBatchOrganizerAsync: (query: downloadBatchQueryFilters, batchIds: string[], selectedDownloadType?: DownloadType, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_DOWNLOAD_BATCH_ORGANIZER_ASYNC_LOADING,
            loading: true
        })
        fetch(`${apiPrefix}BatchDownload/DownloadBatchOrganizerAsync`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                query,
                batchIds
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then((data: any) => {
                if (selectedDownloadType == DownloadType.DownloadNow) {
                    if (Helper.SessionStore.isExists("DownloadNowIds")) {
                        let DownloadNowIds = Helper.SessionStore.get("DownloadNowIds");
                        var ids = JSON.parse(DownloadNowIds) as string[];
                        ids.push(data.id)
                        Helper.SessionStore.set("DownloadNowIds", JSON.stringify(ids));
                    }
                    else {
                        let ids = [data.id];
                        Helper.SessionStore.set("DownloadNowIds", JSON.stringify(ids));
                    }
                }
                dispatch({
                    type: actionTypes.SET_DOWNLOAD_BULK_ORGANIZER_ASYNC,
                    bulkOrganizerAsyncResponse: data
                });
                dispatch({
                    type: actionTypes.SET_DOWNLOAD_BATCH_ORGANIZER_ASYNC_LOADING,
                    loading: false
                })
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.CompanySettings,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`removeOrganizerReportFilter failed for the request  with error ${error.message}`)
            });
        
    },

    downloadBulkBatchOrganizerAsync: (query: downloadBatchQueryFilters, selectedDownloadType?: DownloadType, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {

        const state = getState();
        dispatch({
            type: actionTypes.SET_DOWNLOAD_BULK_BATCH_ORGANIZER_ASYNC_LOADING,
            loading: true
        })
        fetch(`${apiPrefix}BatchDownload/DownloadBulkOrganizerAsync`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then((response: any) => {

                if (response.id == undefined) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileError,
                        statusType: StatusType.Error,
                    });
                }
                else {
                    if (state.userSettings.settings.defaultUserSettings.download.rememberDefaultDownload == true) {
                        selectedDownloadType = state.userSettings.settings.defaultUserSettings.download.downloadType;
                    }
                    if (selectedDownloadType == DownloadType.DownloadNow) {
                        if (Helper.SessionStore.isExists("DownloadNowIds")) {
                            let DownloadNowIds = Helper.SessionStore.get("DownloadNowIds");
                            var ids = JSON.parse(DownloadNowIds) as string[];
                            ids.push(response.id)
                            Helper.SessionStore.set("DownloadNowIds", JSON.stringify(ids));
                        }
                        else {
                            let ids = [response.id];
                            Helper.SessionStore.set("DownloadNowIds", JSON.stringify(ids));
                        }
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileNowWaitProcessing,
                            statusType: StatusType.Success
                        });
                    }

                    else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileWaitProcessing,
                            statusType: StatusType.Success
                        });
                    }
                    let data = state.companyData.myDownloads;
                    data.unshift(response);
                    dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, myDownloadsList: data })

                }
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileError,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                if (callback) {
                    callback();
                }
            });
        

    }
}

export const reducer: Reducer<DownloadOrganizerStoreState> = (state: DownloadOrganizerStoreState = downloadOrganizerStoreState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {

        case actionTypes.SET_DOWNLOAD_ORGANIZER_ASYNC:
            const { downloadOrganizerAsyncResponse } = action;
            return {
                ...state,
                downloadOrganizerAsync: {
                    ...state.downloadOrganizerAsync,
                    data: downloadOrganizerAsyncResponse
                }
            }
        case actionTypes.SET_DOWNLOAD_BATCH_ORGANIZER_ASYNC:
            const { downloadBatchOrganizerAsyncResponse } = action;
            return {
                ...state,
                downloadBulkOrganizerAsync: {
                    ...state.downloadBulkOrganizerAsync,
                    data: downloadBatchOrganizerAsyncResponse
                }
            }
    }
    return state || downloadOrganizerStoreState;
};