import { Reducer, Action } from "redux";
import { AppThunkAction } from './../../store';
import { actionTypes } from './../../store/ActionTypes';
import { handleResponse } from './../../store/Library';
import { actionTypes as menuActionTypes } from "./../../store/productMenu/ActionTypes/ActionTypes"
import { CompletedProductLeftMenuItems, ReceivedProductLeftMenuItems, RequestProductLeftMenuItems, MenuInitialState, apiPrefix, ILeftMenuData, RequestDisableLeftMenu, IProductData, IProduct } from './model/ProductMenuStoreModel';
import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import { IMenuState } from './model/ProductMenuStoreModel';
import { Product } from 'src/Core/ViewModels/User/UserViewModel';
import { GetLeftMenuRouteData } from '../../components/helper/Rbac/RBACHelper'
import { IsAccessibleProduct } from 'src/oidcClient/userRoles';

interface ReceiveUserAccessibleProductAction {
    type: actionTypes.RECEIVE_USER_ACCESSIBLE_PRODUCT;
    isOrganizerAccessible: boolean;
}
interface RequestUserAccessibleProductAction {
    type: actionTypes.REQUEST_USER_ACCESSIBLE_PRODUCT;
}
const unloadedAccessibleProductsState: IProductData = {
    isAccessible: false,
    isInitialState: true
};
export type DispatchActions = RequestProductLeftMenuItems |
    ReceivedProductLeftMenuItems |
    CompletedProductLeftMenuItems |
    RequestDisableLeftMenu |
    ReceiveUserAccessibleProductAction |
    RequestUserAccessibleProductAction;

type KnownActions = DispatchActions | NotificationAction;

export const actionCreators = {

    getProductLeftMenuItems: (navigate?: (data: any) => void): AppThunkAction<KnownActions> =>
        (dispatch, getState) => {
            dispatch({
                type: menuActionTypes.REQUEST_PRODUCT_LEFTMENU_ITEMS,
            });

            fetch(`${apiPrefix}menu/product-left-menu/${Product.Organizers}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response)
                .then((data) => {
                    const response: ILeftMenuData = {
                        leftMenuData: data,
                        routeList: GetLeftMenuRouteData(data)
                    }
                    dispatch({
                        type: menuActionTypes.RECEIVED_PRODUCT_LEFTMENU_ITEMS,
                        payload: response
                    });
                    navigate && navigate(response.routeList);
                })
                .catch((error) => {
                    dispatch({
                        type: menuActionTypes.COMPLETED_PRODUCT_LEFTMENU_ITEMS,
                    });
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                });
            
        },

    requestUserAccessibleProducts: (): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let state = getState().AccessibleProductData;

        if (!state || state.isInitialState) {
            const fetchTask = fetch(`${apiPrefix}menu/accessible-products`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then((response) => response.json() as Promise<IProduct[]>)
                .then((response) => {
                    var isAccessible = IsAccessibleProduct(response);
                    dispatch({ type: actionTypes.RECEIVE_USER_ACCESSIBLE_PRODUCT, isOrganizerAccessible: isAccessible })
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                });
            
            dispatch({ type: actionTypes.REQUEST_USER_ACCESSIBLE_PRODUCT });
        }
    }
}
export const productsReducer: Reducer<IProductData> = (state = unloadedAccessibleProductsState, incomingAction) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.RECEIVE_USER_ACCESSIBLE_PRODUCT:
            let receivedaccessibleProducts = { ...state };
            receivedaccessibleProducts.isAccessible = action.isOrganizerAccessible;
            receivedaccessibleProducts.isInitialState = false;
            return receivedaccessibleProducts;
        case actionTypes.REQUEST_USER_ACCESSIBLE_PRODUCT:
            let accessibleProducts = { ...state };
            return accessibleProducts;
        default:
            const exhaustiveCheck: any = action;
    }
    return state;
};

export const reducer: Reducer<IMenuState> = (
    (menuState: IMenuState = MenuInitialState, incomingAction: Action) => {
        const action = incomingAction as DispatchActions;
        let newMenuState = { ...menuState }
        switch (action.type) {
            case menuActionTypes.REQUEST_PRODUCT_LEFTMENU_ITEMS:
                newMenuState.isLoading = true;
                return newMenuState;
            case menuActionTypes.RECEIVED_PRODUCT_LEFTMENU_ITEMS:
                newMenuState.productLeftMenu = action.payload.leftMenuData;
                newMenuState.isRoutesEmpty = action.payload.leftMenuData.length == 0;
                newMenuState.routeList = action.payload.routeList;
                newMenuState.isAllRoutesDisabled = action.payload.routeList.every(r => !r.isEnabled);
                newMenuState.isLoading = false;
                return newMenuState;
            case menuActionTypes.COMPLETED_PRODUCT_LEFTMENU_ITEMS:
                newMenuState.isLoading = false;
                return newMenuState;
            case menuActionTypes.REQUEST_DISABLE_LEFTMENU:
                newMenuState.hideLeftmenu = true;
                newMenuState.isAllRoutesDisabled = true;
                return newMenuState;
            default:
                return newMenuState;
        }
    }
);