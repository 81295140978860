import { Action, Reducer } from 'redux';
import { AppThunkAction } from './../../store';
import { handleResponse, handleBlobwithFileName } from './../../store/Library';
import { actionTypes } from './../../store/ActionTypes'
import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import { ClientTypes } from 'src/components/common/TaxReturn';
import * as  BatchStore from '../../Organizer/store/BatchStore';

import {
    apiPrefix,
    BatchReceiveOrganizerClient,
    BatchDeliveredOrganizerPopupLoader,
    BatchOrganizerClientsSaved,
    BatchReceiveOrganizerClientTracking,
    BatchDownloadFilledOrganizerAction,
    BatchDownloadEngagementLetterAction,
    BatchDownloadSourceFileAction,
    BatchReceiveSourceFilesAction,
    BatchReceiveOrganizerAccessCodeAction,
    BatchStoreState,
    initialBatchStoreState,
    BatchTableModel,
    OrganizerTableModel,
    DeliveredBatchPageLoaderAction,
    BatchDeliveredOrganizerPageLoaderAction,
    RecieveDeliveredOrganizerBatchAction,
    RecieveBatchDeliveredOrganizersAction,
    UpdateDeliveredBatchInfoAction,
    ReceiveBatchDownloadableDocumentsAction,
    UpdateOrganizerBatchDownloadDetailsAction,
    UpdateEngagementBatchDownloadDetailsAction,
    UpdateSourceBatchDownloadDetailsAction,
    BatchBulkOperationReminderRequest,
    UnlockOrganizerAction
} from './../models/OrganizerStoreModels';
import {
    DownloadableDocuments,
    DownloadDocumentType,
    EngagementLetterStatus,
    OrganizerClient, OrganizerClientType,
    OrganizerDocument,
    OrganizerStatus,
    OrganizerTransaction,
    SourceDocumentStatus
} from './../models/OrganizerComponentModels';
import { StoreConstants } from './../components/Helper/OrganizerConstants';
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';
import { IAccessCode, IAccessViewModel } from '../../components/common/TaxReturn';
import * as moment from 'moment';
import { TYPES } from '../../Startup/types';
import { IDialogBox } from '../../Core/Utilities/UI/DialogBox';
import { container } from '../../Startup/inversify.config';
import { HideLoader } from '../../components/helper/Loader';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { VenusNotifier } from 'src/components/helper/VenusNotifier';
const logger = new LoggerFactory().getTelemetryLogger();



type KnownActions =
    UpdateOrganizerBatchDownloadDetailsAction |
    UpdateEngagementBatchDownloadDetailsAction |
    UpdateSourceBatchDownloadDetailsAction |
    ReceiveBatchDownloadableDocumentsAction |
    UpdateDeliveredBatchInfoAction |
    RecieveBatchDeliveredOrganizersAction |
    RecieveDeliveredOrganizerBatchAction |
    BatchDeliveredOrganizerPageLoaderAction |
    DeliveredBatchPageLoaderAction |
    BatchReceiveOrganizerClient |
    BatchDeliveredOrganizerPopupLoader |
    BatchOrganizerClientsSaved |
    BatchReceiveOrganizerClientTracking |
    BatchDownloadFilledOrganizerAction |
    BatchDownloadEngagementLetterAction |
    BatchDownloadSourceFileAction |
    BatchReceiveSourceFilesAction |
    BatchReceiveOrganizerAccessCodeAction |
    UnlockOrganizerAction |
    NotificationAction;


let expandedDeliveredOrganizersAbortController = new AbortController();
const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
export const actionCreator = {

    requestDeliveredBatch: (query: string, reload = false, callback?: any): AppThunkAction<KnownActions> => (dispatch, getState) => {

        dispatch({
            type: actionTypes.DELIVERED_BATCH_PAGE_LOADER,
            loading: true
        });

        //const appState = getState();
        //const pagedBatch = appState.pagedBatchStore.batches[query];
        //if (!reload && pagedBatch) {
        //    dispatch({
        //        type: actionTypes.RECEIVE_ORGANIZER_BATCHES,
        //        batchModel: pagedBatch
        //    });

        //    dispatch({
        //        type: actionTypes.DELIVERED_BATCH_PAGE_LOADER,
        //        loading: false
        //    });
        //    return;
        //}

        fetch(`${apiPrefix}BatchDeliveredOrganizer/${query}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as BatchTableModel)
            .then(data => {

                dispatch({
                    type: actionTypes.RECEIVE_DELIVERED_ORGANIZER_BATCHES,
                    batchModel: data
                });

                //dispatch({
                //    type: actionTypes.RECEIVE_PAGED_ORGANIZER_BATCHES,
                //    batchModel: data,
                //    query: query
                //});

                dispatch({
                    type: actionTypes.DELIVERED_BATCH_PAGE_LOADER,
                    loading: false
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.AllOrganizers,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
            });
        
    },

    exportDeliveredBatchOrganizersAsExcel: (query: string, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        fetch(`${apiPrefix}BatchDeliveredOrganizer/ExportExcel/${query}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => {
                if (callback) {
                    callback();
                }
            }).catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.Export, statusType: StatusType.Error, statusCode: error?.status });
                if (callback) {
                    callback();
                }
            });
        
    },

    requestBatchDeliveredOrganizers: (batchGuid: string, query: string): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.BATCH_DELIVERED_ORGANIZER_PAGE_LOADER,
            loading: true
        });

        //const appState = getState();
        //const pagedBatchOrganizers = appState.pagesBatchOrganizerStore.organizers[query];
        //if (pagedBatchOrganizers) {
        //    dispatch({
        //        type: actionTypes.RECEIVE_BATCH_ORGANIZERS,
        //        organizerModel: pagedBatchOrganizers,
        //        batchGuid: batchGuid
        //    });

        //    dispatch({
        //        type: actionTypes.BATCH_DELIVERED_ORGANIZER_PAGE_LOADER,
        //        loading: false
        //    });
        //    return;
        //}

        expandedDeliveredOrganizersAbortController.abort();
        expandedDeliveredOrganizersAbortController = new AbortController();
        fetch(`${apiPrefix}BatchDeliveredOrganizer/GetBatchDeliveredOrganizers/${query}`, {
            method: 'GET',
            credentials: 'include',
            signal: expandedDeliveredOrganizersAbortController.signal
        })
            .then(handleResponse)
            .then(response => response as OrganizerTableModel)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_BATCH_DELIVERED_ORGANIZERS,
                    organizerModel: data,
                    batchGuid: batchGuid
                });

                //dispatch({
                //    type: actionTypes.RECEIVE_PAGED_BATCH_ORGANIZERS,
                //    organizers: data,
                //    query: query
                //});

                dispatch({
                    type: actionTypes.BATCH_DELIVERED_ORGANIZER_PAGE_LOADER,
                    loading: false
                });
            })
            .catch((error) => {
                if (error.name === 'AbortError') {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.AllOrganizers,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
            });
        
    },

    deleteBatchOrganizer: (id: number, callback: () => void, batchGuid: string): AppThunkAction<KnownActions> => (dispatch, getState) => {

        fetch(`${apiPrefix}DeliveredOrganizer/${id}`, {
            method: 'DELETE',
            credentials: 'include'
        })
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.Delete,
                    statusType: StatusType.Success
                });
                callback();
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.Delete,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
            });
        
    },

    unlockDocument: (documentId: number, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        fetch(`${apiPrefix}DeliveredOrganizer/UnlockDocument/${documentId}`, {
            method: 'PUT',
            credentials: 'include',
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.UNLOCK_LOCKED_DOCUMENT, id: documentId });
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.UnlockOrganizer, statusType: StatusType.Success });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error, statusCode: error?.status });
            });
        
    },

    generateClientViewForBatchIndividual: (doucment: OrganizerDocument, clientType: OrganizerClientType, batchId: string, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let action: any = BatchStore.actionCreator.generateBatchIndividualTaxPayerClientView(doucment, clientType, batchId, callback);
        dispatch(action);
    },

    resendBatchOrganizerAccessLink: (url: string, model: OrganizerClient): AppThunkAction<KnownActions> => (dispatch, getState) => {

        dispatch({
            type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
            loading: true
        });

        fetch(url, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(model),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.ResendAccessLink,
                    statusType: StatusType.Success
                });
                dispatch({
                    type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                    loading: false
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.ResendAccessLink,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
            });
        
    },

    onConfirmReopenBatchOrganizerClick: (documentId: number, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
            loading: true
        });
        fetch(`${apiPrefix}DeliveredOrganizer/ReopenOrganizer/${documentId}`, {
            method: 'PUT',
            credentials: 'include',
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.ReopenOrganizer,
                    statusType: StatusType.Success
                });
                dispatch({
                    type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                    loading: false
                });
                callback();
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.ReopenOrganizer,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
            });
        
    },


    batchRequestDeliveryStatus: (id: number, batchGuid: string, callback?: (clients: OrganizerClient[]) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER, loading: true });
        let fetchTask = fetch(`${apiPrefix}Details/GetDeliveryStatus/${id}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as OrganizerClient[])
            .then((data) => {
                dispatch({ type: actionTypes.BATCH_RECEIVE_ORGANIZER_DOCUMENT_CLIENTS, id: id, clients: data, batchGuid: batchGuid });
                if (callback) {
                    callback(data);
                }
                dispatch({
                    type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                    loading: false
                });
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.OrganizerClients, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`batchRequestDeliveryStatus failed for the request having parameters DocumentID: ${id} and BatchID: ${batchGuid} with error ${error.message}`)
                dispatch({
                    type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                    loading: false
                });
            });
        
    },

    requestBatchOrganizerClients: (id: number, batchGuid: string, callback?: (data: OrganizerClient[]) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {

        dispatch({
            type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
            loading: true
        });
        fetch(`${apiPrefix}Details/Clients/${id}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as OrganizerClient[])
            .then((data) => {
                dispatch({
                    type: actionTypes.BATCH_RECEIVE_ORGANIZER_DOCUMENT_CLIENTS,
                    id: id,
                    clients: data,
                    batchGuid: batchGuid
                });
                if (callback) {
                    callback(data);
                }
                dispatch({
                    type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                    loading: false
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.OrganizerClients,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
            });
        
    },

    saveBatchOrganizerClientInfo: (clientInfo: OrganizerClient[], clientId: string, documentId: number, callback: () => void,
        batchGuid: string, resendEmail: boolean | false, isTpEmailupdated?: boolean, isSpouseEmailupdated?: boolean):
        AppThunkAction<KnownActions> => (dispatch, getState) => {

            dispatch({
                type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                loading: true
            });
            const formData = JSON.stringify({
                documentId: documentId,
                clientId: clientId,
                resendEmail: resendEmail,
                isTpEmailupdated: isTpEmailupdated,
                isSpouseEmailupdated: isSpouseEmailupdated,
                clientInfo: clientInfo
            });
            fetch(`${apiPrefix}Details/Clients`, {
                method: 'POST',
                credentials: 'include',
                body: formData,
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(handleResponse)
                .then(() => {
                    const taxpayer = clientInfo.find(client => client.clientType === OrganizerClientType.Taxpayer);
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Success.ClientInfo,
                        statusType: StatusType.Success
                    });
                    dispatch({
                        type: actionTypes.BATCH_DELIVERED_ORGANIZER_DOCUMENT_CLIENTS_SAVED,
                        clientId: clientId,
                        name: taxpayer ? taxpayer.name : "",
                        documentId: documentId,
                        clients: clientInfo,
                        batchGuid: batchGuid
                    });
                    dispatch({
                        type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                        loading: false
                    });
                    callback();
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Failure.ClientInfo,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                    logger?.trackError(`saveBatchOrganizerClientInfo failed for the request having parameters ${JSON.stringify(clientInfo)} with error ${error.message}`)
                });
            
        },

    requestBatchOrganizerClientTracking: (documentId: number, batchGuid: string): AppThunkAction<KnownActions> => (dispatch, getState) => {

        dispatch({
            type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
            loading: true
        });
        fetch(`${apiPrefix}DeliveredOrganizer/GetOrganizerClientTracking/${documentId}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as OrganizerTransaction[])
            .then((data) => {
                dispatch({
                    type: actionTypes.BATCH_RECEIVE_ORGANIZER_CLIENT_TRACKING,
                    id: documentId,
                    data: data,
                    batchGuid: batchGuid
                });
                dispatch({
                    type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                    loading: false
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.ClientTracking,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`requestBatchOrganizerClientTracking failed for the request having parameters ${documentId} with error ${error.message}`)
            });
        
    },

    downloadBatchOrganizerFilledOrganizer: (documentGuid: string,
        year: number, clientId: string, log: boolean, batchGuid: string): AppThunkAction<KnownActions> => (dispatch, getState) => {

            const appState = getState();
            dispatch({
                type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                loading: true
            });

            fetch(`${apiPrefix}Document/GetFilledOrganizer/${documentGuid}/${year}/${log}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleBlobwithFileName)
                .then((data) => {
                    const displayDownloadFile = new DisplayDownloadFile();
                    displayDownloadFile.showFile(data.blob, `${year}_${clientId}_Organizer.pdf`);
                    dispatch({
                        type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                        loading: false
                    });
                    dispatch({
                        type: actionTypes.BATCH_FILLED_ORGANIZER_DOWNLOAD,
                        id: documentGuid,
                        batchGuid: batchGuid
                    });
                    if (log) {
                        dispatch({
                            type: actionTypes.UPDATE_ORGANIZER_BATCH_DOWNLOAD_DETAILS,
                            id: documentGuid,
                            userName: `${appState.userProfile.firstName} ${appState.userProfile.lastName}`,
                            batchGuid: batchGuid
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Failure.FilledOrganizer,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                    logger?.trackError(`downloadBatchOrganizerFilledOrganizer failed for the request having parameters ${documentGuid}/${year}/${log} with error ${error.message}`)
                });
            
        },

    downloadBatchOrganizerSignedEngagementLetter: (documentGuid: string, year: number,
        clientId: string, log: boolean, batchGuid: string): AppThunkAction<KnownActions> => (dispatch, getState) => {

            const appState = getState();
            dispatch({
                type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                loading: true
            });

            fetch(`${apiPrefix}Document/GetSignedEngagementLetter/${documentGuid}/${year}/${log}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleBlobwithFileName)
                .then((data) => {
                    const displayDownloadFile = new DisplayDownloadFile();
                    displayDownloadFile.showFile(data.blob, data.filename);
                    dispatch({
                        type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                        loading: false
                    });
                    dispatch({
                        type: actionTypes.BATCH_SIGNED_ENGAGEMENTLETTER_DOWNLOAD,
                        id: documentGuid,
                        batchGuid: batchGuid
                    });
                    if (log) {
                        dispatch({
                            type: actionTypes.UPDATE_ENGAGEMENT_BATCH_DOWNLOAD_DETAILS,
                            id: documentGuid,
                            userName: `${appState.userProfile.firstName} ${appState.userProfile.lastName}`,
                            batchGuid: batchGuid
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Failure.SignedEngagementLetter,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                    logger?.trackError(`downloadBatchOrganizerSignedEngagementLetter failed for the request having parameters ${documentGuid}/${year}/${log} with error ${error.message}`)
                });
            
        },

    downloadBatchOrganizerSourceDocument: (documentId: number, fileName: string,
        documentGuid: string, year: number, clientId: string, batchGuid: string): AppThunkAction<KnownActions> => (dispatch, getState) => {

            const appState = getState();
            dispatch({
                type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                loading: true
            });

            fetch(`${apiPrefix}Document/GetSourceDocument/${documentId}/${fileName}/${documentGuid}/${year}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleBlobwithFileName)
                .then((data) => {
                    const displayDownloadFile = new DisplayDownloadFile();
                    displayDownloadFile.showFile(data.blob, `${year}_${clientId}_${data.filename}`);
                    dispatch({
                        type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                        loading: false
                    });
                    dispatch({
                        type: actionTypes.UPDATE_SOURCE_BATCH_DOWNLOAD_DETAILS,
                        fileName: fileName,
                        id: documentId,
                        userName: `${appState.userProfile.firstName} ${appState.userProfile.lastName}`,
                        batchGuid: batchGuid
                    });
                    dispatch({
                        type: actionTypes.BATCH_SOURCE_FILE_DOWNLOAD,
                        id: documentGuid,
                        batchGuid: batchGuid
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Failure.SourceDocument,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                    logger?.trackError(`downloadBatchOrganizerSourceDocument failed for the request having parameters ${documentId}/${fileName}/${documentGuid}/${year} with error ${error.message}`)
                });
            
        },

    getBatchOrganizerSourceFiles: (documentId: number, batchGuid: string): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
            loading: true
        });

        fetch(`${apiPrefix}DeliveredOrganizer/GetSourceFiles/${documentId}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as string[])
            .then((data) => {
                dispatch({
                    type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                    loading: false
                });
                dispatch({
                    type: actionTypes.BATCH_RECEIVE_SOURCE_FILES,
                    id: documentId,
                    files: data,
                    batchGuid: batchGuid
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.SignedEngagementLetter,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`getBatchOrganizerSourceFiles failed for the request having parameters ${documentId} with error ${error.message}`)
            });
        
    },


    getBatchDownloadableDocuments: (documentId: number, batchGuid: string, clientId: string, batchName: string, taxYear: number): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER, loading: true });
        let fetchTask = fetch(`${apiPrefix}DeliveredOrganizer/GetDownloadableDocuments/${documentId}/${encodeURIComponent(clientId)}/${batchGuid}/${encodeURIComponent(batchName)}/${taxYear}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as DownloadableDocuments[])
            .then((data) => {
                dispatch({
                    type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                    loading: false
                });
                dispatch({
                    type: actionTypes.RECEIVE_BATCH_ORGANIZER_DOWNLOADABLE_DOCUMENTS,
                    id: documentId,
                    documents: data,
                    batchGuid: batchGuid
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.DownloadDocuments,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`getBatchDownloadableDocuments failed for the request having parameters ${documentId} with error ${error.message}`)
            });
        
    },

    requestBatchOrganizerAccessCode: (documentId: number, batchGuid: string): AppThunkAction<KnownActions> => (dispatch, getState) => {

        dispatch({
            type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
            loading: true
        });

        fetch(`${apiPrefix}Document/AccessCode/${documentId}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<IAccessViewModel>)
            .then(data => {
                dispatch({
                    type: actionTypes.BATCH_RECIEVE_ORGANIZER_ACCESSCODE,
                    id: documentId,
                    accessCode: data,
                    batchGuid: batchGuid
                });
                dispatch({
                    type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER,
                    loading: false
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.AccessCode,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`requestBatchOrganizerAccessCode failed for the request having parameters ${documentId} with error ${error.message}`)
            });
        
    },

    generateBatchOrganizerOTP: (documentId: number, batchGuid: string, url: string, callBack?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {

        fetch(url, {
            method: 'PUT',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(() => {
                const action: any = actionCreator.requestBatchOrganizerAccessCode(documentId, batchGuid);
                dispatch(action);
                if (callBack) {
                    callBack();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.SaveAccessCode,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`generateBatchOrganizerOTP failed for the request having parameters ${documentId} with error ${error.message}`)
            });
        
    },


    deleteBatch: (batchId: string, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        fetch(`${apiPrefix}Batch/${batchId}`, {
            method: 'DELETE',
            credentials: 'include'
        })
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.DeleteBatch,
                    statusType: StatusType.Success
                });
                callback();
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.DeleteBatch,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`deleteBatch failed for the request having parameters ${batchId} with error ${error.message}`)
            });
        
    },

    updateBatchInfo: (batchName: string, batchGuid: string, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        fetch(`${apiPrefix}Batch/UpdateBatchInfo/${encodeURIComponent(batchName)}/${batchGuid}`, {
            method: 'POST',
            credentials: 'include'
        })
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.BatchInfo,
                    statusType: StatusType.Success
                });
                dispatch({
                    type: actionTypes.UPDATE_DELIVERED_BATCH_INFO,
                    batchGuid: batchGuid,
                    batchName: batchName
                })
                callback();
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.BatchInfo,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`updateBatchInfo failed for the request having parameters ${batchName}/${batchGuid} with error ${error.message}`)
            });
        
    },


    downloadAllBatch: (
        apiUrl: string,
        batchGuid: string,
        documentId: number, documentGuid: string, year: number,
        clientId: string, logOrganizer: boolean,
        logEngage: boolean, fileNames: string[], callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {

            dispatch({ type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER, loading: true });
            const appState = getState();
            const formData = JSON.stringify({
                documentId: documentId,
                documentGuid: documentGuid,
                year: year,
                clientId: clientId,
                logOrganizer: logOrganizer,
                logEngage: logEngage,
                fileNames: fileNames
            });
            let fetchTask = fetch(apiPrefix + apiUrl, {
                method: 'POST',
                credentials: 'include',
                body: formData,
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(handleBlobwithFileName)
                .then((data) => {
                    if (data.blob.type.indexOf("application/json") !== -1) {
                        dialogBox.alert(StoreConstants.Success.DownloadAllNotification);
                        if (callback) {
                            callback();
                        }
                    }
                    else {
                        let displayDownloadFile = new DisplayDownloadFile();
                        displayDownloadFile.showFile(data.blob, `${clientId}_${year}_${data.filename}`);
                        if (logOrganizer) {
                            dispatch({
                                type: actionTypes.BATCH_FILLED_ORGANIZER_DOWNLOAD,
                                id: documentGuid,
                                batchGuid: batchGuid
                            });
                            dispatch({
                                type: actionTypes.UPDATE_ORGANIZER_BATCH_DOWNLOAD_DETAILS,
                                id: documentGuid,
                                userName: `${appState.userProfile.firstName} ${appState.userProfile.lastName}`,
                                batchGuid: batchGuid
                            });
                        }

                        if (logEngage) {
                            dispatch({
                                type: actionTypes.BATCH_SIGNED_ENGAGEMENTLETTER_DOWNLOAD,
                                id: documentGuid,
                                batchGuid: batchGuid
                            });
                            dispatch({
                                type: actionTypes.UPDATE_ENGAGEMENT_BATCH_DOWNLOAD_DETAILS,
                                id: documentGuid,
                                userName: `${appState.userProfile.firstName} ${appState.userProfile.lastName}`,
                                batchGuid: batchGuid
                            });
                        }

                        if (fileNames && fileNames.length) {
                            fileNames.forEach(fileName => {
                                dispatch({
                                    type: actionTypes.UPDATE_SOURCE_BATCH_DOWNLOAD_DETAILS,
                                    fileName: fileName,
                                    id: documentId,
                                    userName: `${appState.userProfile.firstName} ${appState.userProfile.lastName}`,
                                    batchGuid: batchGuid
                                });
                            });
                            dispatch({
                                type: actionTypes.BATCH_SOURCE_FILE_DOWNLOAD,
                                id: documentGuid,
                                batchGuid: batchGuid
                            });
                        }

                    }
                    dispatch({ type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER, loading: false });

                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Failure.DownloadAll,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                    logger?.trackError(`downloadAllBatch failed for the request having parameters ${documentId}/${documentGuid}/${year}/${encodeURI(clientId)}/${logOrganizer}/${logEngage} with error ${error.message}`)
                });
            
        },

    downloadSourceDocumentsBatch: (
        apiUrl: string,
        batchGuid: string,
        documentId: number, year: number,
        clientId: string,
        fileNames: string[], documentGuid: string,
        isDownloadAllSourceDocuments: boolean): AppThunkAction<KnownActions> => (dispatch, getState) => {

            dispatch({ type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER, loading: true });
            const appState = getState();

            const payloadData = JSON.stringify({
                "documentId": documentId,
                "isDownloadAllSourceDocuments": isDownloadAllSourceDocuments,
            });

            let fetchTask = fetch(apiPrefix + apiUrl, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: payloadData
            })
                .then(handleBlobwithFileName)
                .then((data) => {
                    VenusNotifier.Success(StoreConstants.Success.IndividualDownloadAllNotifcation, null)
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Failure.DownloadAll,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                    logger?.trackError(`Download source documents batch failed for the request having parameters ${documentId}/${year}/${encodeURI(clientId)} with error ${error.message}`)
                });
            
        },

    sendReminder: (batchIds: string[], isBulkSelectionEnabled: boolean, reminderRequest: BatchBulkOperationReminderRequest, callback?: () => void):
        AppThunkAction<KnownActions> => (dispatch, getState) => {


            let apiEndPoint = isBulkSelectionEnabled ? `${apiPrefix}BulkOperation/BatchBulkSendReminder` : `${apiPrefix}BulkOperation/BatchSendReminder`;
            let body = isBulkSelectionEnabled ? JSON.stringify(reminderRequest) : JSON.stringify({ query: reminderRequest, batchIds: batchIds });

            let fetchTask = fetch(apiEndPoint, {
                method: 'POST',
                credentials: 'include',
                body: body,
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(handleResponse)
                .then((response) => {
                    HideLoader()
                    if (callback) {
                        callback();
                    }
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.SendReminderBulkWaitMessage, statusType: StatusType.Success });
                })
                .catch(error => {
                    HideLoader()
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.SendReminder, statusType: StatusType.Error, statusCode: error?.status });
                    logger?.trackError(`sendReminder failed for the request having parameters ${(isBulkSelectionEnabled ? JSON.stringify(reminderRequest) : JSON.stringify({ query: reminderRequest, batchIds: batchIds }))} with error ${error.message}`)
                });
            
        },
}

export const reducer: Reducer<BatchStoreState> = (state: BatchStoreState = initialBatchStoreState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {


        case actionTypes.RECEIVE_BATCH_DELIVERED_ORGANIZERS: {
            const batchOrganizers = { ...state };
            batchOrganizers.batchModel.batches = batchOrganizers.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers = action.organizerModel
                }
                return batch;
            });
            return batchOrganizers;
        }

        case actionTypes.RECEIVE_DELIVERED_ORGANIZER_BATCHES: {
            const batches = { ...state };
            batches.batchModel = action.batchModel;
            return batches;
        }


        case actionTypes.UPDATE_DELIVERED_BATCH_INFO: {
            const batchInfo = { ...state };
            batchInfo.batchModel.batches = batchInfo.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.batchName = action.batchName
                }
                return batch;
            });
            return batchInfo;
        }

        case actionTypes.BATCH_FILLED_ORGANIZER_DOWNLOAD: {
            const downloadState = { ...state };
            downloadState.batchModel.batches = downloadState.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers.documents = batch.organizers.documents.map(org => {
                        if (action.id === org.processInfoGuid &&
                            org.organizerStatus === OrganizerStatus.Completed) {
                            return {
                                ...org,
                                organizerStatus: OrganizerStatus.OrganizerDownloaded
                            }
                        }
                        return org;
                    });
                }
                return batch;
            });
            return downloadState;
        }

        case actionTypes.BATCH_SIGNED_ENGAGEMENTLETTER_DOWNLOAD: {
            const downloadState = { ...state };
            downloadState.batchModel.batches = downloadState.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers.documents = batch.organizers.documents.map(org => {
                        if (action.id === org.processInfoGuid &&
                            org.engagementLetterStatus === EngagementLetterStatus.ESigned) {
                            return {
                                ...org,
                                engagementLetterStatus: EngagementLetterStatus.EngagementLetterDownloaded
                            }
                        }
                        return org;
                    });
                }
                return batch;
            });
            return downloadState;
        }

        case actionTypes.BATCH_SOURCE_FILE_DOWNLOAD: {
            const downloadState = { ...state };
            downloadState.batchModel.batches = downloadState.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers.documents = batch.organizers.documents.map(org => {
                        if (action.id === org.processInfoGuid) {
                            if (org.uploadedSourceDocumentsCount > 0
                                && org.uploadedSourceDocumentsCount == org.downloadedSourceDocumentsCount) {
                                return {
                                    ...org,
                                    sourceDocumentStatus: SourceDocumentStatus.Downloaded
                                }
                            }
                        }
                        return org;
                    });
                }
                return batch;
            });
            return downloadState;
        }

        case actionTypes.BATCH_RECEIVE_SOURCE_FILES: {
            const downloadState = { ...state };
            downloadState.batchModel.batches = downloadState.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers.documents = batch.organizers.documents.map(org => {
                        if (org.id === action.id) {
                            return {
                                ...org,
                                uploadedFileNames: action.files
                            }
                        }
                        return org;
                    });
                }
                return batch;
            });
            return downloadState;
        }

        case actionTypes.BATCH_RECEIVE_ORGANIZER_DOCUMENT_CLIENTS: {
            const clients = { ...state };
            clients.batchModel.batches = clients.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers.documents = batch.organizers.documents.map(org => {
                        if (org.id === action.id) {
                            return {
                                ...org,
                                organizerClients: action.clients
                            }
                        }
                        return org;
                    });
                }
                return batch;
            });
            return clients;
        }

        case actionTypes.DELIVERED_BATCH_PAGE_LOADER: {
            return {
                ...state,
                loading: action.loading
            }
        }

        case actionTypes.BATCH_DELIVERED_ORGANIZER_PAGE_LOADER: {
            return {
                ...state,
                organizerTableLoading: action.loading
            }
        }

        case actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER: {
            return {
                ...state,
                popupLoader: action.loading
            }
        }

        case actionTypes.BATCH_DELIVERED_ORGANIZER_DOCUMENT_CLIENTS_SAVED: {

            const clientSaved = { ...state };
            clientSaved.batchModel.batches = clientSaved.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers.documents = batch.organizers.documents.map(org => {
                        if (org.id === action.documentId) {
                            return {
                                ...org,
                                clientId: action.clientId,
                                taxpayerName: action.name,
                                organizerClients: action.clients
                            }
                        }
                        return org;
                    });
                }
                return batch;
            });
            return clientSaved;
        }

        case actionTypes.BATCH_RECEIVE_ORGANIZER_CLIENT_TRACKING: {
            const clientTracking = { ...state };
            clientTracking.batchModel.batches = clientTracking.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers.documents = batch.organizers.documents.map(org => {
                        if (org.id === action.id) {
                            return {
                                ...org,
                                clientTracking: action.data
                            }
                        }
                        return org;
                    });
                }
                return batch;
            });
            return clientTracking;
        }


        case actionTypes.BATCH_RECIEVE_ORGANIZER_ACCESSCODE: {
            const accessCode = { ...state };
            accessCode.batchModel.batches = accessCode.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers.documents = batch.organizers.documents.map(org => {
                        if (org.id === action.id) {
                            return {
                                ...org,
                                accessCode: action.accessCode
                            }
                        }
                        return org;
                    });
                }
                return batch;
            });
            return accessCode;
        }


        case actionTypes.RECEIVE_BATCH_ORGANIZER_DOWNLOADABLE_DOCUMENTS: {
            const documents = { ...state };
            documents.batchModel.batches = documents.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers.documents = batch.organizers.documents.map(org => {
                        if (org.id === action.id)
                            return { ...org, downloadDocuments: action.documents }
                        return { ...org };
                    });
                }
                return batch;
            });
            return documents;
        }


        case actionTypes.UPDATE_ORGANIZER_BATCH_DOWNLOAD_DETAILS: {
            const orgDetails = { ...state };
            orgDetails.batchModel.batches = orgDetails.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers.documents = batch.organizers.documents.map(org => {

                        if (org.processInfoGuid === action.id) {
                            if (!org.downloadDocuments ||
                                !org.downloadDocuments.length) {
                                org.downloadDocuments = [];
                            }
                            const found = org.downloadDocuments.find(doc => doc.type === DownloadDocumentType.Organizer);
                            if (!found) {
                                const downloadDoc: DownloadableDocuments = {
                                    downloadBy: action.userName,
                                    downloadDate: moment.utc(new Date()).format() as any,
                                    fileName: 'Organizer',
                                    type: DownloadDocumentType.Organizer,
                                    uploadedOn: undefined
                                };
                                org.downloadDocuments.push(downloadDoc);
                            } else {
                                org.downloadDocuments = org.downloadDocuments.map(doc => {
                                    if (doc.type === DownloadDocumentType.Organizer) {
                                        doc.downloadBy = action.userName;
                                        doc.downloadDate = moment.utc(new Date()).format() as any;
                                    }
                                    return { ...doc };
                                })
                            }
                            return { ...org };
                        }
                        return { ...org };
                    });
                }
                return batch;
            });
            return orgDetails;
        }

        case actionTypes.UPDATE_ENGAGEMENT_BATCH_DOWNLOAD_DETAILS: {
            const engDetails = { ...state };
            engDetails.batchModel.batches = engDetails.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers.documents = batch.organizers.documents.map(org => {
                        if (org.processInfoGuid === action.id) {
                            if (!org.downloadDocuments ||
                                !org.downloadDocuments.length) {
                                org.downloadDocuments = [];
                            }
                            const found = org.downloadDocuments.find(doc => doc.type === DownloadDocumentType.EngagementLetter);
                            if (!found) {
                                const downloadDoc: DownloadableDocuments = {
                                    downloadBy: action.userName,
                                    downloadDate: moment.utc(new Date()).format() as any,
                                    fileName: 'EnagagementLetter',
                                    type: DownloadDocumentType.EngagementLetter,
                                    uploadedOn: undefined
                                };
                                org.downloadDocuments.push(downloadDoc);
                            } else {
                                org.downloadDocuments = org.downloadDocuments.map(doc => {
                                    if (doc.type === DownloadDocumentType.EngagementLetter) {
                                        doc.downloadBy = action.userName;
                                        doc.downloadDate = moment.utc(new Date()).format() as any;
                                    }
                                    return { ...doc };
                                })
                            }
                            return { ...org };
                        }
                        return { ...org };
                    });
                }
                return batch;
            });
            return engDetails;
        }

        case actionTypes.UPDATE_SOURCE_BATCH_DOWNLOAD_DETAILS: {
            const sourceDetails = { ...state };
            sourceDetails.batchModel.batches = sourceDetails.batchModel.batches.map(batch => {
                if (batch.id === action.batchGuid) {
                    batch.organizers.documents = batch.organizers.documents.map(org => {
                        if (org.id === action.id) {
                            if (!org.downloadDocuments ||
                                !org.downloadDocuments.length) {
                                org.downloadDocuments = [];
                            }
                            const found = org.downloadDocuments.find(doc => doc.type === DownloadDocumentType.SourceDocument &&
                                doc.fileName === action.fileName);
                            if (!found) {
                                const downloadDoc: DownloadableDocuments = {
                                    downloadBy: action.userName,
                                    downloadDate: moment.utc(new Date()).format() as any,
                                    fileName: action.fileName,
                                    type: DownloadDocumentType.SourceDocument,
                                    uploadedOn: undefined
                                };
                                org.downloadDocuments.push(downloadDoc);
                            } else {
                                org.downloadDocuments = org.downloadDocuments.map(doc => {
                                    if (doc.type === DownloadDocumentType.SourceDocument &&
                                        doc.fileName === action.fileName) {
                                        doc.downloadBy = action.userName;
                                        doc.downloadDate = moment.utc(new Date()).format() as any;
                                    }
                                    return { ...doc };
                                })
                            }

                            org.downloadedSourceDocumentsCount = org.downloadDocuments.filter(x => x.type == DownloadDocumentType.SourceDocument && x.downloadDate != null).length;
                            org.uploadedSourceDocumentsCount = org.downloadDocuments.filter(x => x.type == DownloadDocumentType.SourceDocument).length;

                            return { ...org };
                        }
                        return { ...org };
                    });
                }
                return batch;
            });
            return sourceDetails;
        }

    }
    return state || initialBatchStoreState;
}