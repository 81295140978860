import * as React from 'react';
import Layout from "@sssuite-component-ui/ss-suite-layout";
import { ICommonSettings, ICompanySettings } from '../Core/ViewModels/Company/CompanySettingsViewModel';
import { IDropdownMenuItem } from './navigation/DropDown';
import { LogoutCause, SignalRWebSocket } from '../Organizer/components/SignalR/SignalRWebSocket';
import { ResourceIdConstants } from './helper/Rbac/RbacConstants';
import { IMenuSection, ISideMenuProps } from '@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu';
import { UserAutoLogoutModal } from './navigation/UserAutoLogoutModal';
import 'src/Core/Services/FetchInterceptorService';
import { ISectionData } from '@sssuite-component-ui/ss-suite-layout/dist/Header/NavigationWidget/NavigationWidget';
import { HelpLink } from './../components/navigation/help/helpLink';
import { IHeaderProps } from '@sssuite-component-ui/ss-suite-layout/dist/Header/Header';
import { ApplicationState } from '../store';
import { useSelector } from 'react-redux';
import { actionCreators as UserSettingsActionCreators } from '../store/UserManagement/UserSettingStore'
import { actionCreators as UserLoginHistoryActionCreators } from '../store/UserManagement/UserLoginHistoryStore'
import { actionCreators as AllUsersActionCreators } from '../store/UserManagement/UserStore'
import { actionCreators as UserProfileActionCreators } from '../store/UserManagement/UserProfileStore'
import { actionCreators as OneTimePasswordActionCreators } from '../store/common/OneTimePasswordStore'
import { actionCreators as CompanyActionCreators } from '../store/company/CompanyStore'
import { actionCreators as DeliveredOrganizersActionCreators } from '../Organizer/store/DeliveredOrganizerStore'
import { actionCreators as RecycledOrganizersActionCreators } from '../Organizer/store/RecycledOrganizerStore'
import { actionCreators as RbacActionCreators } from '../store/roleBasedAccessControl/RoleBasedAccessControlStore'
import { MyAccount } from '../components/navigation/profile/MyAccount';
import { IUserPassword } from '../components/navigation/profile/ProfileObjects';
import MySettings, { IUserDefaultSettings, IUserDefaultSettingsStates } from '../components/navigation/profile/MySettings';
import * as UserHelper from '../components/helper/UserHelperFunctions';
import MyDownload, { IDownloadedZipFilesModel } from '../components/navigation/profile/MyDownload';
import { MyLoginHistory } from '../components/navigation/profile/MyLoginHistory';
import Logout from '../components/navigation/profile/Logout';
import { useDispatch } from 'react-redux';
import { IUserPrivilege } from '../store/UserManagement/UserPrivilegeStore';
import { SupportIcon } from '../assets/SvgIconCollection';
import { ICompanyLogoSetting } from 'src/Core/ViewModels/Company/CompanyViewModel';
import { IAuthState, initialAuthState } from 'src/store/auth/reducer';
import { signoutRedirect } from 'src/oidcClient/userService';
import { openWidgetOnClick } from "@sssuite-component-ui/forethought-ai-chatbot";
import store from 'src/configureStore';

export interface LayoutProps {
    children?: React.ReactNode;
    history: any;
}

export enum ProductType {
    None = 0,
    Returns = 1,
    SignaturesOneOff = 2,
    SignaturesMailMerge = 3,
    Organizers = 4,
    Extensions = 5,
    Exchange = 6,
    //Not a product,client.
    SSSuitePortal = 1001,
}

const LayoutWrapper: React.FC<LayoutProps> = (props) => {


    const dispatch = useDispatch();
    const usersList = useSelector((state: ApplicationState) => state.usersList);
    const user = useSelector((state: ApplicationState) => state.auth.user);
    const profile = useSelector((state: ApplicationState) => state.userProfile);
    const partners = useSelector((state: ApplicationState) => state.partners);
    const settings = useSelector((state: ApplicationState) => state.userSettings);
    const companyData = useSelector((state: ApplicationState) => state.companyData);
    const userLoginHistory = useSelector((state: ApplicationState) => state.userLoginHistory);
    const oneTimePasswordData = useSelector((state: ApplicationState) => state.oneTimePasswordStore);
    const suiteUrl = useSelector((state: ApplicationState) => state.widgetMenuControl.suiteUrl);
    const organizerCompanySettings = useSelector((state: ApplicationState) => state.organizerCompanySettings);
    const [localAuthState, setlocalAuthState] = React.useState<IAuthState>(initialAuthState);

    const menuSections = useSelector((state: ApplicationState) => state.productMenuControl.productLeftMenu);
    const widgetMenu = useSelector((state: ApplicationState) => state.widgetMenuControl.widgetMenu);
    const company_name = useSelector((state: ApplicationState) => state.companyData.companyProfile.companyInfo.companyName);
    const companyLogo = useSelector((state: ApplicationState) => state.companyData.companyLogoSetting);
    const organizerDownloadData = useSelector((state: ApplicationState) => state.downloadOrganizerStore.downloadOrganizerAsync);
    const bulkOrganizerDownloadData = useSelector((state: ApplicationState) => state.downloadOrganizerStore.downloadBulkOrganizerAsync);
    const userPrevilage = useSelector((state: ApplicationState) => state.userPrivilege);

    const isFeatureEnabled = useSelector((state: ApplicationState) => state.companyData.isCompanyOrganizerProductEnabled);
    const isSubscriptionEnabled = useSelector((state: ApplicationState) => state.companyData.isCompanyOrganizerProductEnabled);
    const isAllRoutesDisabled = useSelector((state: ApplicationState) => state.productMenuControl.isAllRoutesDisabled);

    React.useEffect(()=>{
        if(user && user?.profile && organizerCompanySettings.companySettings.defaultSettings.savedMessage!==0){
            injectPendoScript();
        }
    },[user.profile, organizerCompanySettings.companySettings])
    
    const onMyDownloadToggle = () => {
        setMyDownloadState((prevState) => {
            return {
                ...prevState,
                showState: !prevState.showState
            }
        });
    }

    const onMyAccountToggle = () => {
        setProfileDropDownState((prevState) => {
            return {
                ...prevState,
                showState: !prevState.showState
            }
        });
    }

    const onMyAccountCancel = () => {
        setProfileDropDownState((prevState) => {
            return {
                ...prevState,
                showState: false
            }
        });
    }

    const onMySettingsToggle = () => {
        setMySettingsState((prevState) => {
            return {
                ...prevState,
                showState: !prevState.showState
            }
        });
    }

    const onLogoutToggle = () => {
        setLogoutState((prevState) => {
            return {
                ...prevState,
                showState: !prevState.showState
            }
        });
    }

    const onLoginHistoryToggle = () => {
        setLoginHistoryState((prevState) => {
            return {
                ...prevState,
                showState: !prevState.showState
            }
        });
    }

    const onClearAllDownloads = () => {
        setMyDownloadList([])
    }

    const onClearSingleDownload = (jobId: string) => {
        const downloads = [...myDownloadList].filter((download: any) => download.jobId !== jobId)
        setMyDownloadList(downloads);
    }

    const setDataForSideMenu = (menuSections: IMenuSection[], hideLeftMenu: boolean): ISideMenuProps => {

        const sideMenuProps: ISideMenuProps = {
            hasButton: false,
            topMenuData: [],
            bottomMenuData: [],
            onNavigate: (route: string) => {
                props.history.push(route);
            },
            hideSideMenu: hideLeftMenu,
            buttonIcon: '',
            buttonText: '',
            onButtonClick: () => { }
        };

        if (menuSections && menuSections.length) {
            let topMenuData = menuSections.filter(x => x.items.some(y => !y.isAtBottomOfLeftMenu));
            sideMenuProps.hasButton = false;
            sideMenuProps.bottomMenuData = menuSections.filter(x => x.items.some(y => y.isAtBottomOfLeftMenu));
            sideMenuProps.topMenuData = topMenuData.filter(section => !(section.items.length == 1 && section.items[0].caption === "Settings" && section.items[0].disabled));
        }

        return sideMenuProps
    }

    const getSupportIcon = () => {
        return <div className="col-md-3 bootstrap-grid-no-padding">
            <SupportIcon
                className="support-icon"
                onClick={openWidgetOnClick}
                title="Support"
            ></SupportIcon>
        </div>
    }
    var injectPendoScript = () => {
        const {
            company_id,
            company_name,
            user_id,
            given_name: firstName,
            family_name: lastName,
            email,
        } = user?.profile;

        if (!company_id || !company_name || !user_id || !firstName || !lastName || !email) {
            console.log("User profile not available yet");
            return;
        }

        const apiKey = process.env.REACT_APP_PENDO_API_KEY;

        (function (p: any, e: any, n: any, d: any, o: any) {
            var v: any, w: any, x: any, y: any, z: any;
            o = p[d] = p[d] || {};
            o._q = o._q || [];
            v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
            for (w = 0, x = v.length; w < x; ++w)
                (function (m) {
                    o[m] =
                        o[m] ||
                        function () {
                            o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
                        };
                })(v[w]);
            y = e.createElement(n);
            y.async = !0;
            y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
            z = e.getElementsByTagName(n)[0];
            z.parentNode.insertBefore(y, z);
        })(window, document, "script", "pendo", "");
        window.pendo?.initialize({
            visitor: {
                id: `${company_id}-${user_id}-${email}`,
                email: email,
                firstName: firstName,
                lastName: lastName
            },
            account: {
                accountid: company_id,
                accountname: company_name,
                firmtype: organizerCompanySettings?.companySettings?.isTestCompany ? 'Internal' : 'Live',
                subscription: organizerCompanySettings?.companySettings?.companySubscription
            }
        });
    };
    const [headerModel, setHeaderModel] = React.useState<IHeaderProps>({
        productName: ProductType.Organizers,
        companyLogoMaxWidth: "120px",
        onClickProductLogo: () => { },
        profileDropDown: {
            dropdownHeader: `Hi !`,
            dropdownHeaderTitle: "",
            onClickLogout: onLogoutToggle,
            profileDropDownItems: [
                {
                    caption: 'Profile',
                    onClick: onMyAccountToggle
                },
                {
                    caption: 'My Settings',
                    onClick: onMySettingsToggle
                },
                {
                    caption: 'My Downloads',
                    onClick: onMyDownloadToggle
                },
                {
                    caption: 'Login History',
                    onClick: onLoginHistoryToggle
                },
                {
                    caption: 'Logout',
                    onClick: onLogoutToggle
                }
            ]
        },
        widgetData: {
            title:"SafeSend ONE",
            onBackClick: () => { }, dropDownData: []
        }
    });

    const [myDownloadList, setMyDownloadList] = React.useState<IDownloadedZipFilesModel[]>(companyData.myDownloads || []);

    const [isNewDownloadFetched, setIsNewDownloadFetched] = React.useState<boolean>(false);

    const [localLogoutState, setLocalLogoutState] = React.useState<IUserPrivilege>({ userPrivilegesChanged: false, logoutCause: LogoutCause.None });

    const setNewDownloadFetched = (val: boolean) => {
        setIsNewDownloadFetched(val)
    }

    const [profileDropDownState, setProfileDropDownState] = React.useState<IDropdownMenuItem>({
        caption: 'Profile',
        route: '#',
        icon: 'fas fa-user ddl-icon',
        showState: false,
        onClick: onMyAccountToggle,
        onHide: onMyAccountCancel,
        onShow: onMyAccountToggle
    });

    const [mySettingsState, setMySettingsState] = React.useState<IDropdownMenuItem>({
        caption: 'My Settings',
        route: '#',
        icon: 'fas fa-cogs ddl-icon',
        showState: false,
        onClick: onMySettingsToggle,
        onHide: onMySettingsToggle,
        onShow: onMySettingsToggle
    });

    const [myDownloadState, setMyDownloadState] = React.useState<IDropdownMenuItem>({
        caption: 'My Downloads',
        route: '#',
        icon: 'fa fa-download ddl-icon',
        showState: false,
        onClick: onMyDownloadToggle,
        onHide: onMyDownloadToggle,
        onShow: onMyDownloadToggle
    });

    const [loginHistoryState, setLoginHistoryState] = React.useState<IDropdownMenuItem>({
        caption: 'Login History',
        route: '#',
        icon: 'fas fa-history ddl-icon',
        showState: false,
        onClick: onLoginHistoryToggle,
        onHide: onLoginHistoryToggle,
        onShow: onLoginHistoryToggle
    });


    const [logoutState, setLogoutState] = React.useState<IDropdownMenuItem>({
        caption: 'Logout',
        route: '#',
        icon: 'fas fa-sign-out-alt ddl-icon',
        showState: false,
        onClick: onLogoutToggle,
        onHide: onLogoutToggle,
        onShow: onLogoutToggle
    });

    React.useEffect(() => {
        setHeaderModel(headerConfig(
            company_name,
            profile.firstName,
            widgetMenu,
            suiteUrl,
            companyLogo
        ));
    }, [widgetMenu, company_name, profile, companyLogo]);

    React.useEffect(() => {
        const idOrganizerAsync = organizerDownloadData.data.id;
        const isItemAlreadyExistingInState = myDownloadList?.some((r: any) => r.id == idOrganizerAsync);
        if (!isItemAlreadyExistingInState) {
            setMyDownloadList([
                ...myDownloadList,
                organizerDownloadData.data
            ]);
        }
    }, [organizerDownloadData]);

    React.useEffect(() => {
        const idBulkOrganizerAsync = bulkOrganizerDownloadData.data.id;
        const isBatchItemAlreadyExistingInState = myDownloadList.some((r: any) => r.id == idBulkOrganizerAsync)
        if (!isBatchItemAlreadyExistingInState) {
            setMyDownloadList([
                ...myDownloadList,
                bulkOrganizerDownloadData.data
            ]);
        }
    }, [bulkOrganizerDownloadData]);

    React.useEffect(() => {
        if (myDownloadState.showState && !isNewDownloadFetched) {
            dispatch(CompanyActionCreators.getMyDownloadsListAsync(() => {
                setIsNewDownloadFetched(true);
            }))
        }
    }), [isNewDownloadFetched];

    React.useEffect(() => {
        if (userPrevilage.userPrivilegesChanged != localLogoutState.userPrivilegesChanged) {
            setLocalLogoutState(userPrevilage)
        }
    }, [userPrevilage.userPrivilegesChanged]);

    React.useEffect(() => {
        setMyDownloadList(companyData.myDownloads);
    }, [companyData.myDownloads]);

    const headerConfig = (
        companyName: string,
        firstName: string,
        widgetMenus: ISectionData[],
        suiteUrl: string,
        companyLogoSetting: ICompanyLogoSetting
    ): IHeaderProps => {
        let headerprops: IHeaderProps = {
            productName: ProductType.Organizers,
            onClickProductLogo: () => { props.history.push("/") },
            children: getSupportIcon(),
            profileDropDown: {
                dropdownHeader: `Hi, ${firstName?.trim()}!`,
                dropdownHeaderTitle: `${firstName?.trim()}`,
                onClickLogout: onLogoutToggle,
                profileDropDownItems: [
                    {
                        caption: 'Profile',
                        onClick: onMyAccountToggle
                    },
                    {
                        caption: 'My Settings',
                        onClick: onMySettingsToggle
                    },
                    {
                        caption: 'My Downloads',
                        onClick: onMyDownloadToggle
                    },
                    {
                        caption: 'Login History',
                        onClick: onLoginHistoryToggle
                    },
                ]
            },
            widgetData: {
                onBackClick: () => {
                    window.open(suiteUrl, '_blank');
                },
                title:"SafeSend ONE",
                dropDownData: widgetMenus
            },
        };
        if (companyLogoSetting.isSsrLogo) {
            headerprops.companyName = companyName;
        } else {
            headerprops.companyLogo = companyLogoSetting.logoPath;
        }
        return headerprops;
    };

    const getEroList = () => UserHelper.convertPartnersToDropDownList(partners.partners);

    const updateUsersCallback = () => {
        dispatch(AllUsersActionCreators.requestAllUsers(true));
        dispatch(AllUsersActionCreators.requestPartners(true));
    }
    const hideSideMenu = companyData.companyProfile.companyInfo.id == 0;
    
    return (<>
        <Layout headerProps={headerModel} sideMenuProps={setDataForSideMenu(menuSections, hideSideMenu || isAllRoutesDisabled || !isFeatureEnabled || !isSubscriptionEnabled)}>
            <div className="main-container">
                {props.children}
            </div>
        </Layout>
        <div>
            <MyAccount {...profileDropDownState}
                profile={profile}
                users={usersList.users}
                saveUserProfile={(userInfo: any, notification: boolean, onResponse: (response: any, error: any) => void) => { dispatch(UserProfileActionCreators.saveMyAccount(userInfo, notification, onResponse)) }}
                updatePassword={(payload: IUserPassword, notification: boolean, onResponse: (response: any, error: any) => void) => { dispatch(UserProfileActionCreators.updatePassword(payload, notification, onResponse)) }}
                requestAllUsers={(reload: boolean = false) => { dispatch(AllUsersActionCreators.requestAllUsers(reload)) }}
                companySettings={companyData.companySettings as ICompanySettings}
                saveOneTimePassword={(mobileNumber: string, countryCode: string) => { dispatch(OneTimePasswordActionCreators.saveOneTimePassword(mobileNumber, countryCode)) }}
                otpData={oneTimePasswordData.otpState}
                validateOTP={(otp: string, mobileNumber: string, callback: () => void) => { dispatch(OneTimePasswordActionCreators.validateOTP(otp, mobileNumber, callback)) }}
                verifyLoading={companyData.companyProfile.companyInfo.id == 0? false : oneTimePasswordData.isVerifyLoading}
                updateUsersCallback={updateUsersCallback}
                commonSettings={companyData.commonSettings as ICommonSettings}
            />
            <MySettings {...mySettingsState}
                users={usersList.users}
                my={settings}
                onHide={onMySettingsToggle}
                verifyLoading={oneTimePasswordData.isVerifyLoading}
                loggedinUser={profile.userId}
                saveUserDefaultSettings={(userId: number, settings: IUserDefaultSettings, callback?: () => void) => { dispatch(UserSettingsActionCreators.saveUserDefaultSettings(userId, settings, callback)) }}
                eroList={getEroList()}
                updateUsersCallback={updateUsersCallback}
            />
            <MyDownload
                {...myDownloadState}
                onHide={onMyDownloadToggle}
                downloadsList={myDownloadList}
                downloadReturnFromPopup={(jobId: string, fileName: string, isDownloadNow?: boolean, callback?: (url: string) => void) => { dispatch(CompanyActionCreators.downloadReturnFromPopup(jobId, fileName, isDownloadNow, callback)) }}
                clearAllMyDownloads={(callback?: () => void) => { dispatch(CompanyActionCreators.clearAllMyDownloads(callback)) }}
                deleteMyDownloads={(jobId: string, isDownloadNow?: boolean, callback?: (jobId: string) => void) => { dispatch(CompanyActionCreators.deleteMyDownloads(jobId, isDownloadNow, callback)) }}
                onClearAllDownloads={onClearAllDownloads}
                onClearSingleDownload={onClearSingleDownload}
                setIsNewDownloadFetched={setNewDownloadFetched}
                companySettingsStore={organizerCompanySettings}
            />
            <MyLoginHistory {...loginHistoryState}
                onHide={onLoginHistoryToggle}
                requestUserLoginHistory={(pageNo: number = 1, pageSize: number = 15) => { dispatch(UserLoginHistoryActionCreators.requestUserLoginHistory(pageNo, pageSize)) }}
                userLoginHistory={userLoginHistory}
                userSettings={settings}
                saveSettings={(settings: IUserDefaultSettingsStates, callback?: () => void) => { dispatch(UserSettingsActionCreators.saveUserSettings(settings, callback)) }}
            />
            {localAuthState.userPrivilegesChanged && <UserAutoLogoutModal openModal={localAuthState.userPrivilegesChanged} logoutCause={localAuthState.userAutoLogoutCause} />}
            <Logout {...logoutState}
            />
        </div>
        <SignalRWebSocket
            profile={profile}
            companyData={companyData}
            getMyDownloadsListAsync={(callback?: (result: any[]) => void) => { dispatch(CompanyActionCreators.getMyDownloadsListAsync(callback)) }}
            refreshDeliveredOrganizers={() => { dispatch(DeliveredOrganizersActionCreators.refreshDeliveredOrganizers()) }}
            refreshRecycledOrganizers={() => { dispatch(RecycledOrganizersActionCreators.refreshRecycledOrganizers()) }}
        />
        <UserAutoLogoutModal
            openModal={localLogoutState.userPrivilegesChanged}
            logoutCause={localLogoutState.logoutCause}
            resetUserCache={() => { dispatch(RbacActionCreators.resetUserCache()) }}
        />
    </>
    );
}

export default LayoutWrapper;
