import { Action, Reducer } from "redux";
import { AppThunkAction } from "..";

export interface PostAuthStoreState {
    extensionEnabled: boolean;
    loading: boolean;
    loggedOut: boolean;
}

const initalPostAuthStoreState: PostAuthStoreState = {
    extensionEnabled: false,
    loading: false,
    loggedOut: false
}

type KnownAction = LoaderAction | RecieveDataAction | LoggedOutAction;

export interface LoaderAction {
    type: "PostAuthStoreLoader";
    loading: boolean;
}

export interface RecieveDataAction {
    type: "PostAuthStoreReceiveData";
    extensionEnabled: boolean;
}

export interface LoggedOutAction {
    type: "LoggedOut";
    loggedOut: boolean;
}

let authDataExists = false;

export const actionCreators = {

    loggedOut: () : AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "LoggedOut", loggedOut: true });
    }
}

export const reducer: Reducer<PostAuthStoreState> = (
    state: PostAuthStoreState = initalPostAuthStoreState,
    incomingAction: Action) => {

    const action = incomingAction as KnownAction;

    switch (action.type) {

        case "PostAuthStoreLoader":
            return { ...state, loading: action.loading }

        case "PostAuthStoreReceiveData":
            return { ...state, extensionEnabled: action.extensionEnabled }

        case "LoggedOut":
            return { ...state, loggedOut: action.loggedOut }

        default: return state || initalPostAuthStoreState;
    }
}