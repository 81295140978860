import { Action, Reducer } from 'redux';
import { AppThunkAction } from './../../store';
import { handleResponse } from './../../store/Library';
import { actionTypes } from './../../store/ActionTypes'
import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import {
    InprocessOrganizerFilterStoreState,
    DeleteInprocessOrganizerFilterAction,
    ReceiveInprocessOrganizerFilterAction,
    RequestInprocessOrganizerFilterAction,
    SaveInprocessOrganizerFilterAction,
    unloadedInprocessOrganizerFilterStoreState,
    UpdateInprocessOrganizerFilterAction,
    apiPrefix
} from './../models/OrganizerStoreModels';
import { OrganizerFilter, OrganizerFilterType } from './../models/OrganizerComponentModels';
import { SignatureFlowReportFilterConstants } from './../../components/helper/Constants';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { ReportFilterConstants} from './../../components/helper/Constants';
const logger = new LoggerFactory().getTelemetryLogger();

type KnownActions =
    RequestInprocessOrganizerFilterAction |
    ReceiveInprocessOrganizerFilterAction |
    SaveInprocessOrganizerFilterAction |
    UpdateInprocessOrganizerFilterAction |
    DeleteInprocessOrganizerFilterAction |
    NotificationAction;

export const actionCreators = {

    requestOrganizerReportFilter: (type: OrganizerFilterType): AppThunkAction<KnownActions> => (dispatch, getState) => {
        fetch(`${apiPrefix}/Filter/${type}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<OrganizerFilter[]>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_INPROCESS_ORGANIZER_FILTER, filters: data, loading: false });
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: StatusType.Error,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.GetAllErrorMessage,
                    statusCode: error?.status
                });
                logger?.trackError(`requestOrganizerReportFilter failed for the request having parameters ${type} with error ${error.message}`)
            });
       
        dispatch({ type: actionTypes.REQUEST_INPROCESS_ORGANIZER_FILTER, loading: true });
    },

    saveOrganizerReportFilter: (name: string, filter: OrganizerFilter, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {

        let options: any = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(filter)
        };

        fetch(`${apiPrefix}/Filter/${name}`, options)
            .then(handleResponse)
            .then(() => {
                filter.name = name;
                dispatch({ type: actionTypes.SAVE_INPROCESS_ORGANIZER_FILTER, filter: filter });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: StatusType.Success,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.SavedSuccessMessage
                });
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: StatusType.Error,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.SavedErrorMessage,
                    statusCode: error?.status
                });
                logger?.trackError(`saveOrganizerReportFilter failed for the request having parameters ${name} ${JSON.stringify(filter)} with error ${error.message}`)
            });
       
    },

    updateOrganizerReportFilter: (name: string, actionName: string, filter: OrganizerFilter,
        callback?: (name: string) => void, showLoader?: boolean): AppThunkAction<KnownActions> => (dispatch, getState) => {

        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(filter)
        };

        let statusMessageText: string = 
            actionName === ReportFilterConstants.ControlsLabel.RemoveDefault
            ? SignatureFlowReportFilterConstants.FilterSuccessMessage.RemoveDefaultFilterSuccess
            : SignatureFlowReportFilterConstants.StatusMessage.UpdateSuccessMessage;

        fetch(`${apiPrefix}/Filter/${name}`, options)
            .then(handleResponse)
            .then(() => {
                filter.name = name;
                dispatch({ type: actionTypes.UPDATE_INPROCESS_ORGANIZER_FILTER, filter: filter });
                if (showLoader) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusType: StatusType.Success,
                        statusMessage: statusMessageText
                    });
                }
                if (callback) {
                    callback(name);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusType: StatusType.Error, statusMessage: SignatureFlowReportFilterConstants.StatusMessage.UpdateErrorMessage, statusCode: error?.status });
                logger?.trackError(`updateOrganizerReportFilter failed for the request having parameters ${name} ${JSON.stringify(filter)} with error ${error.message}`)
            });
       
    },

    removeOrganizerReportFilter: (name: string, type: OrganizerFilterType, callback?: (name: string) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {

        fetch(`${apiPrefix}/Filter/${name}/${type}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.DELETE_INPROCESS_ORGANIZER_FILTER, name: name });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: StatusType.Success,
                    statusMessage: SignatureFlowReportFilterConstants.StatusMessage.DeleteSuccessMessage
                });
                if (callback) {
                    callback(name);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusType: StatusType.Error, statusMessage: SignatureFlowReportFilterConstants.StatusMessage.DeleteErrorMessage, statusCode: error?.status });
                logger?.trackError(`removeOrganizerReportFilter failed for the request having parameters ${name} ${type} with error ${error.message}`)
            });
       
    }
};

export const reducer: Reducer<InprocessOrganizerFilterStoreState> = (state: InprocessOrganizerFilterStoreState = unloadedInprocessOrganizerFilterStoreState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.REQUEST_INPROCESS_ORGANIZER_FILTER:
            return {
                ...unloadedInprocessOrganizerFilterStoreState,
                loading: action.loading
            }

        case actionTypes.RECEIVE_INPROCESS_ORGANIZER_FILTER:
            return {
                filters: action.filters,
                loading: action.loading
            }

        case actionTypes.SAVE_INPROCESS_ORGANIZER_FILTER:
            let saveReport: InprocessOrganizerFilterStoreState = { ...state };
            saveReport.filters.push(action.filter);
            saveReport.loading = false;
            return saveReport;

        case actionTypes.UPDATE_INPROCESS_ORGANIZER_FILTER:
            let updated: InprocessOrganizerFilterStoreState = { ...state };
            updated.filters = updated.filters.map((item, i) => {
                return item.name == action.filter.name ? action.filter : item;
            });
            updated.loading = false;
            return updated;

        case actionTypes.DELETE_INPROCESS_ORGANIZER_FILTER:
            let deleteReport: InprocessOrganizerFilterStoreState = { ...state };
            deleteReport.filters.splice(deleteReport.filters.findIndex(x => x.name == action.name), 1);
            return deleteReport;
    }
    return state || unloadedInprocessOrganizerFilterStoreState;
};